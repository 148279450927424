.galeria-album{
  ul{
    padding: 0 .55em;
    li{
      max-height: 350px;
      overflow: hidden;
      margin:0;
      padding:3px;
      position: relative;
      img{
        margin: 0;
      }
      figure{
        position: absolute;
        bottom:-50px;
        left:15px;
        max-width: 90%;
        text-shadow: 0 1px 1px #000;
        margin: 0;
        @include single-transition();
        opacity: 0;
      }
      a{
        @include undolink;
        color:#fff;
        background-color: #767676;
        &:hover{
          figure{
            bottom:15px;
            opacity: 1;
          }
        }
      }

    }
  }
}
.gallery-image-heading {
  background-color: #000;
    background-size: cover;
    background-position: 50%;
    color: #fff;
    margin-bottom: 3px;
    height: 500px;
    position: relative;
  .overlay{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.6);
    z-index: 1;
    height: 100%;
  }
  div.text{
    position: absolute;
    z-index: 2;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    width: 100%;
    text-align: center;
    margin: 0 auto;
    h1{
      @include typi('biggest');
      text-shadow: 0 1px 1px #000;
      text-transform: none;
    }
  }
}
.other-albums{
  color: $body-font-color;
  @include undolink;
  position: relative;
  padding-left: vr(1.5);
  svg{
    position: absolute;
    left: 0;
  }
}
.pswp__share-tooltip a{
  @include undolink;
}
.galleries{
  a{
    @include undolink;
    color:#fff;
    text-shadow: 0 1px 1px #000;
    img{
      margin: 0;
      border: 5px solid #fff;
      box-shadow: 1px 1px 2px #e6e6e6;

    }
    &:hover{

      .wrapper{
        div.descr{
          background: rgba($secondary-color,1);
        }
      }
    }
  }
  .wrapper{
    position: relative;
    div.descr{
      position: absolute;
      bottom: 5px;
      left: 5px;
      right: 5px;
      padding:vr(.25) vr(.5);
      background-color: $secondary-color;
      background: rgba($secondary-color,.8);
      @include single-transition();
      h2{
        font-size: rem-calc(18);
        margin: 0;
      }
      p{
        margin: 0;
        @include typi('small');
      }
    }
  }
}
.main-footer {
  color: #fff;
  margin-top: vr(2);
  text-align: center;
  .recerca &, .colabora &, .agenda &, .avisa & {
      margin-top: 0;
    }
  @media #{$medium-up} {
    margin-top: vr(3);
    .recerca &, .colabora & {
      margin-top: 0;
    }
  }
  @media #{$large-up} {
    margin-top: vr(4);
    .recerca &, .colabora & {
      margin-top: 0;
    }
  }
  &--collapse {
    margin-top: 0;
  }
  a {
    color: #fff;
    @include undolink;
  }
  .collapse & {
    margin-top: 0;
  }
  .social{
    margin-bottom: vr(.75);
  }
}
// .social {
// }
.footer-1 {
  background-color: $primary-color;
  background-image: linear-gradient(236deg, #008ac0, #00528c);
  padding: vr(1) 0;
  overflow: hidden;
}
.footer-2 {
  background-color: $secondary-color;
  padding: vr(1) 0;
  margin-top: -1px;
  ul {
    @include typi("smaller");
  }
}
.footer-nav {
  text-transform: uppercase;
  @include typi("small");
  border-bottom: 1px solid #fff;
  padding-bottom: vr(1);
}
.copy {
  @include typi("smaller");
}

.footer-logos{
  @include block-grid(2);
  margin-bottom: vr(1);
  @media #{$medium-up} {
    @include block-grid(3);
    margin-bottom: vr(1);
  }
  @media #{$large-up} {
    @include block-grid(6);
    margin-bottom: vr(1);
    img {
      margin-bottom: 0;
    }
  }
}

.main-header {
  margin-bottom: vr(1);
  padding-top: vr(.5);
  @media #{$medium-up} {
    margin-bottom: vr(1.5);
    padding-top: vr(1);
  }

  .agenda & {
    margin-bottom: 0;
  }
  &__tools {
    position: absolute;
      top: 0;
      right: 85px;
    @media #{$medium-up} {
      right: 20px;
    }
    @media #{$xlarge-up} {
      right: 0;
    }
    > div {
      display: inline-block;
      vertical-align: top;
    }
    .catsalut {
      @include typi(small);
      @include light;
      line-height: 1.1;
      color:#157CBD;
      @media #{$medium-up} {
        padding-right: vr(1);
        padding-top: vr(.25);
        @include typi(normal);
        line-height: 1.1;
      }
      a{
        color:#157CBD;
        text-decoration: none;
        @include undolink;
        &:hover{
          text-decoration: none;
          color: darken(#157CBD,10%);
        }

      }
      b{
        display: block;
        @include bold;

      }
      span {
        display: block;
        font-size: .8em;
        b{
          @include typi(bigger);
          line-height: 1;
        }
      }
    }

    .btns {
      position: relative;
      display: none;
      @media #{$medium-up} {
        display: inline-block;
      }
      ul.btns-sup {
        list-style: none;
        margin-bottom: 0;
        text-align: right;
        margin-top: -20px;
        li {
          display: inline-block;
        }
      }
      ul.menu-inf {
        list-style: none;
        float:right;
        li {
          display: inline-block;
          a {
            @include undolink;
            color: #00528c;
            font-size: rem-calc(14);
            padding-right: 15px;
            padding-left: 0;
            &:after {
              content: '|';
              color: #979797;
              padding-left: 15px;
            }
          }
          &.last {
            a {
            padding-right: 0;
              &:after {
                content: none;
              }
            }
          }
          .contacte--info{
              background-color: $secondary-color;
              position: absolute;
              top:90px;
              right:90px;
              border-radius: 6px;
              width: 300px;
              padding: vr(.5) vr(1);
              z-index: 2;
              text-align: center;
              h2,p,dl,a{
                color: #fff;
              }
              .title{
                @include bold;
                @include typi(small);
                text-transform: uppercase;
                margin-bottom: vr(.25);
                color:#fff;
              }
              a{
                @include undolink;
                display: inline-block;
                &:after {
                  content: none;
                }
                &:focus{

                    background-color: transparent;
                  }
                &.btn{
                  border-color:#fff;
                  margin: 0 auto;
                  padding: rem-calc(0 15);
                  &:hover{
                    color: $secondary-color;
                    text-decoration: none;
                  }
                }
              }
              dl{
                margin-bottom: vr(.55);
                text-align: left;
              }
              .close{
                background-color: transparent;
                padding: 0;
                margin: 0;
                position: absolute;
                top:14px;
                right:14px;
                width: 16px;
                height: 15px;
                &:before {
                  content: '';
                  display: block;
                  @include sprite('ico-close-bl');
                  position: absolute;
                  top: 0;
                  right: 0;
                }
                &:hover:before{
                  opacity: .85;
                }
              }
              dt{
                @include light;
                font-size: rem-calc(11);
                text-transform: uppercase;
                padding: vr(.35) 0 vr(.15);
              }
              dd{
                margin-left: 0;
                border-bottom: 1px solid rgba(255,255,255, .4);
                padding-bottom: vr(.35);
                &:last-child{
                  border:none;
                }
                a{
                  @include light;
                  font-size: rem-calc(16);
                  padding: 0;
                  margin: 0;
                  line-height: 1;
                  &:hover{
                    background-color: transparent;
                  }
                }
              }


            }
            .traductor--info{
              background-color: $secondary-color;
              position: absolute;

              top:90px;
              right:0;
              border-radius: 6px;
              width: 400px;
              padding: vr(.5) vr(1);
              z-index: 2;
              text-align: center;
              h2,p,a{
                color: #fff;
              }
              .title{
                @include bold;
                @include typi(small);
                text-transform: uppercase;
                margin-bottom: vr(.25);
                color:#fff;
              }
              a{
                @include undolink;
                display: inline-block;
              }
              p{
                @include typi('smallest');
                @include light;
                a{
                  padding: 0;
                  @include regular;
                  @include typi('small');
                }
              }
              ul{
                margin: vr(1) 0;
                li{
                  margin-bottom: 0;
                  a{
                    font-size: rem-calc(16);
                    padding: vr(.6);
                    color: #fff;
                    @include bold;
                    line-height: 1;
                    margin: 0;
                    border: solid 1px #fff;
                    border-radius: 4px;
                    &:hover,&:focus{
                      color: $primary-color;
                      text-decoration: none;
                    }
                  }
                }
              }

              .close{
                background-color: transparent;
                padding: 0;
                margin: 0;
                position: absolute;
                top:14px;
                right:14px;
                width: 16px;
                height: 15px;
                &:before {
                  content: '';
                  display: block;
                  @include sprite('ico-close-bl');
                  position: absolute;
                  top: 0;
                  right: 0;
                }
                &:hover:before{
                  opacity: .85;
                }
              }
            }
        }
      }
      a {
        display: block;
        padding: 0 vr(1);
        font-size: rem-calc(12);
        border-color: #979797;
        color:#000;
        margin-bottom: vr(.6);
        line-height: 34px;
        outline: 0;
        &:hover{
          background-color:#fff;
          //box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
          border-color:#000;
        }
        &:focus{
            border-color:#000;
            color:#000;
            background-color:#fff;
            box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
          }
        /*&.contacte{
          border-color:#a16b14;
          color: #a16b14;

          &:hover{
            background-color:#fff;
            color:#a16b14;
          }
          &:focus{
            border-color:#000;
            color:#000;
            background-color:#fff;
          }
        }*/
        &.colabora {
          background-color: #8cac17;
          border-color: #8cac17;
          color: #fff;

          &:hover{
            background-color:#fff;
            color:#8cac17;
          }
          &:focus{
            border-color:#000;
            color:#000;
            background-color:#fff;
          }
        }
        &.area {
          border-color: #00528c;
          color: #00528c;
          svg {
            margin-left: rem-calc(10);
            fill: #00528C;
          }

          &:hover{
            background-color:#00528c;
            color:#fff;
            svg {
              fill: #fff;
            }
          }
          &:focus{
            border-color:#000;
            color:#000;
            background-color:#fff;
          }
        }
      }
      .contacte--info{
        background-color: $secondary-color;
        position: absolute;
        top:0;
        right:0;
        border-radius: 6px;
        width: 300px;
        padding: vr(.5) vr(1);
        z-index: 2;
        text-align: center;
        h2,p,dl,a{
          color: #fff;
        }
        .title{
          @include bold;
          @include typi(small);
          text-transform: uppercase;
          margin-bottom: vr(.25);
          color:#fff;
        }
        a{
          @include undolink;
          display: inline-block;
          &:focus{

              background-color: transparent;
            }
          &.btn{
            border-color:#fff;
            margin: 0 auto;
            &:hover{
              color: $secondary-color;
              text-decoration: none;
            }
          }
        }
        dl{
          margin-bottom: vr(.55);
          text-align: left;
        }
        .close{
          background-color: transparent;
          padding: 0;
          margin: 0;
          position: absolute;
          top:14px;
          right:14px;
          width: 16px;
          height: 15px;
          &:before {
            content: '';
            display: block;
            @include sprite('ico-close-bl');
            position: absolute;
            top: 0;
            right: 0;
          }
          &:hover:before{
            opacity: .85;
          }
        }
        dt{
          @include light;
          font-size: rem-calc(11);
          text-transform: uppercase;
          padding: vr(.35) 0 vr(.15);
        }
        dd{
          margin-left: 0;
          border-bottom: 1px solid rgba(255,255,255, .4);
          padding-bottom: vr(.35);
          &:last-child{
            border:none;
          }
          a{
            @include light;
            font-size: rem-calc(16);
            padding: 0;
            margin: 0;
            line-height: 1;
            &:hover{
              background-color: transparent;
            }
          }
        }


      }
      .traductor--info{
        background-color: $secondary-color;
        position: absolute;

        top:50px;
        right:0;
        border-radius: 6px;
        width: 400px;
        padding: vr(.5) vr(1);
        z-index: 2;
        text-align: center;
        h2,p,a{
          color: #fff;
        }
        .title{
          @include bold;
          @include typi(small);
          text-transform: uppercase;
          margin-bottom: vr(.25);
          color:#fff;
        }
        a{
          @include undolink;
          display: inline-block;
        }
        p{
          @include typi('smallest');
          @include light;
          a{
            padding: 0;
            @include regular;
            @include typi('small');
          }
        }
        ul{
          margin: vr(1) 0;
          li{
            margin-bottom: 0;
            a{
              font-size: rem-calc(16);
              padding: vr(.6);
              @include bold;
              line-height: 1;
              margin: 0;
              border: solid 1px #fff;
              border-radius: 4px;
              &:hover,&:focus{
                color: $primary-color;
                text-decoration: none;
              }
            }
          }
        }

        .close{
          background-color: transparent;
          padding: 0;
          margin: 0;
          position: absolute;
          top:14px;
          right:14px;
          width: 16px;
          height: 15px;
          &:before {
            content: '';
            display: block;
            @include sprite('ico-close-bl');
            position: absolute;
            top: 0;
            right: 0;
          }
          &:hover:before{
            opacity: .85;
          }
        }
      }
    }
    .intranet{
      width: 34px;
      height: 34px;
      border-radius: 6px;
      display: block;
      background-color: $bg-color;
      @include undolink;
      text-align: center;
      position: relative;
      margin-top: 11px;
      @include single-transition();
      img{
        margin: 0;
        width: 18px;
        height: 18px;
        position: absolute;
        top:50%;
        left:50%;
        margin-top:-9px;
        margin-left:-9px;
      }
      &:hover{
        background-color: darken($bg-color,5%);


      }
    }
    .search{
      //padding-left: vr(1);
      display: none;
      @media #{$medium-up} {
        display: inline-block;
      }
      button.js-button, a.js-button{
        position: relative;
        background-color: $primary-color;
        width: 34px;
        height: 34px;
        border: none;
        border-radius: 6px;
        color:#fff;
        outline: 0;
        top: 27px;
        padding: rem-calc(5);
        @include single-transition();
        &:hover{
          background-color: darken($primary-color,10%);
        }
        &:focus{
          box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
          background-color: darken($primary-color,20%);
        }
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          @include sprite('ico-search');
        }
        &.open{
          background-color: #fff;
          &:before {
            @include sprite('ico-close');
          }
        }
      }
    }
    .sticky-header & {
      display: none !important;
    }
  }
}
.centre{
  .main-header {
    .gradient{
      opacity: 0.43;
      background-image: linear-gradient(-180deg, #007EB5 0%, #F5FAFC 70%, rgba(255,255,255,0.00) 100%);
      width: 100%;
      height: 50px;
      position: absolute;
      top: 0;
      @media #{$medium-up} {
        height: 100px;
      }
    }

  }
}
// .main-header-sub {
//   @media #{$large-up} {
//     padding-top: rem-calc(100);
//     background-color: #fff;
//     position: relative;
//     top: rem-calc(-25);
//     &.sticky {
//       background-color: #fff;
//       position: fixed;
//       width: 100%;
//       z-index: 1;
//       top: rem-calc(51);
//       padding: vr(0.5) 0;
//       transition: top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
//       border-bottom: 1px solid $light;
//     }
//   }
// }
.site-title {
  margin: 0;
  font-size: 1rem;
  float: left;
  padding-bottom: vr(.25);
  @media #{$medium-up} {
    margin: 0 0 vr(1);
    padding-bottom: 0;
  }
  .sticky-header & {
    display: none;
  }
  a {
    @include undolink;
    display: block;
  }
  img.logo-ssibe {
    margin-bottom: 0;
    width:150px;
    max-height: 52px;
    @media #{$medium-up} {
      width:auto;
      max-height: 78px;
      margin-top: 2px;
    }
  }
  #logo-250{
    display: none;
    @media #{$medium-up} {
      display: inline-block;
      margin-right: vr(1);
      margin-bottom: 0;
    }
  }
}
.logo-sticky {
  display: none;
  .sticky-header & {
    display: block;
    float: left;
    // margin-right: vr(1);
    margin-bottom: 0;
  }
}

.mobile-tools{
  padding-bottom: vr(.5);
  @media #{$medium-up} {
    display: none;
  }
  .btns{
    text-align: center;
    .btn{
      color:#fff;
      min-width: 160px;
      svg {
        margin-left: 5px;
      }
    }
    #traductor-mobile--btn[aria-expanded="false"]{
        border-color: #fff!important;
    }
  }
  .traductor--info-mobile{
   position: relative!important;
   a{
    @include undolink;
    color:#fff;
    @include bold;
   }
   p{
    color:#fff;
    @include typi('smallest');
    padding: 0 vr(1);
   }
  }

}
.intranet-mobile{
  a{

      border-radius: 6px;
      display: inline-block;
      background-color: $bg-color;
      @include undolink;
      border-color: #fff;
      color: $body-font-color;
      position: relative;
      margin-top: 11px;
      padding:5px 10px 5px 36px;
      img{
        margin: 0;
        width: 18px;
        height: 18px;
        position: absolute;
        top:50%;
        left:10px;
        margin-top:-9px;
      }
  }
}
.slider__overlay {
	background-color: rgba(#000,.425);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.slick-dotted.slick-slider {
    margin-bottom: 0;
}
.slider {
	text-align: center;
	//overflow: hidden;
	background-size: cover;
	background-position: 0 0;
	//position:relative;
	background-color: $bg-color;
	color: $body-font-color;
    div:not(:first-child) {
        display:none;
    }
    &.slick-initialized {
        div {
            display:block;
        }
    }

	// contenidor imatge
	.slick-slide {
		position: relative;
		background-color: $bg-color;
		color: $body-font-color;
		height: auto;
		img{
			margin: 0;
		}
		@media #{$medium-up} {
			/*height: rem-calc(480);
			> div > div {
				height: rem-calc(480);
			}*/
			.image{
				max-height: rem-calc(480);
				overflow: hidden;
			}
		}
		@media #{$xlarge-up} {

			.image{
				max-height: rem-calc(550);

			}
		}
	}

	// caption
	&__caption {
		text-align: left;

		//transform: translate(-50%,-50%);
		//width: 100%;
		width:auto;
		padding:1em 0 .5em;
		max-width: 525px;
		@media #{$medium-up} {
			background-color: $secondary-color;
			color: #fff;
			background-color: rgba($secondary-color,.75);
			position: absolute;
			padding:.5em 1em;
			z-index: 1;
			left: 0;
			bottom: 75px;
			&--right {
				left: auto;
				right: 0;
			}
		}
		p {
			@include light;
			font-size: rem-calc(16);
			margin-bottom: 0;

			margin-top: vr(.25);
			line-height: 1.5;
			//text-shadow: 0 2px 3px rgba(0,0,0,0.4);
			a {
				@include undolink;
				color: #fff;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
			strong {
				@include bold;
			}
			&.titol{
				font-size: rem-calc(24);
				margin: 0;
				line-height: 1.4;
			}
			@media #{$medium-up} {
				color: #fff;
				font-size: rem-calc(20);
				&.titol{
					font-size: rem-calc(36);
				}

			}
		}
		.btn{
			margin-top: vr(1);
			display: inline-block;

			color: $primary-color;
			border-color: $primary-color;
			@media #{$medium-up} {
				color: #fff;
				border-color: #fff;
			}
			&:hover{
				background-color: #fff;
				color: $primary-color;
			}
		}

		/*.panel {
			color: $body-font-color;
			background-color: #fff;
			padding: vr(1);
			p {
				color: $body-font-color;
				text-shadow:none;
			}
			a {
				@include link($body-font-color, #fff, $body-font-color, $body-font-color);
			}
			.btn {
				@include undolink;
				background-color: $primary-color;
				color: #fff;
				// border: 2px solid $primary-color;
				margin-bottom: 0;
				&:hover {
					// background-color: #fff;
					// color: $primary-color;
					// border-bottom-width: 2px;
					text-decoration: none;
					outline: none;
				}
			}
		}*/
	}
}


// fletxes
.slick-prev,
.slick-next {
	margin-top: 0;
	width: 21px;
	height: 25px;
	display: none!important;
	&:before {
		content: '';
		display: block;
		font-size: 1rem;
	}
	&:hover,
	&:focus {
		&:before {
			opacity: .8;
		}
	}
}
.slick-prev {
    left: vr(1);
    z-index: 1;
    .js-menu-open & {
        z-index: 0;
    }
    &:before {
        //@include sprite('fletxa-esq-blanc');
    }
}
.slick-next {
	right: vr(1);
	&:before {
		//@include sprite('fletxa-dreta-blanc');
	}
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
    opacity: .25 !important;
}


// botons navegació
.slick-dots {
	bottom: vr(.2);
	right: 0;
	//transform: translateX(-50%);
	//background-color: #000;
	//background-color: rgba(0,0,0,.4);
	display: block;
	width: auto;
	padding: vr(.25);
	li {
		vertical-align: middle;
		margin-left: vr(.25);
		margin-right: vr(.25);
		width: vr(.5);
		height: vr(.5);
		&:before {
			display: none;
		}
		button {
			padding: 0;
			width: vr(1);
			height: vr(1);
			opacity: .75;
			color:#000;
			&:before {
				width: vr(.5);
				height: vr(.5);
				left: 0;
				top: 0;
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					/* IE10+ CSS styles go here */
					top: 11px;
				}
				.ie9 & {
					top: 11px;
				}
			}
		}
		&.slick-active button:before {
			color: $secondary-color;
		}
	}
}

.slider-news {
	@media #{$large-up} {
		margin-bottom: vr(1) !important;
	}
	.slick-arrow {
		display: none !important;
		@media #{$large-up} {
			display: block !important;
			top: auto;
			bottom: -20px;
			z-index: 1;
		}
	}
	.slick-prev {
		left: 270px;
		&:after {
			content: '';
			width: 2px;
			height: vr(1.5);
			background-color: #000;
			display: block;
			right: -24px;
			bottom: -5px;
			position: absolute;
		}
		&:before {
		    //@include sprite('fletxa-esq');
		}
	}
	.slick-next {
		right: 270px;
		&:before {
			//@include sprite('fletxa-dreta');
		}
	}
	// .slick-dots {
	// 	border: 1px solid red;
	// 	bottom: -1.5rem;
	// }
}
.section {
	padding: vr(1) 0;
	box-sizing: border-box;
	.row {
		.column,
		.columns {
			>*:last-child:not(hr) {
				margin-bottom: 0;
			}
		}
	}
	@media #{$medium-up} {
		padding: vr(1.5) 0;
	}
	.actualitat & {
		@media #{$medium-up} {
			padding: vr(2) 0;
		}
	}

	&--quick-links{

		h2{
			@include light;
			text-transform: uppercase;
		}
		a{
			outline: 0;
			border-bottom: solid 1px transparent;
			&:focus{
				border-color: $secondary-color;
			}

		}
		.row .row{
    		margin: 0 -.5rem;
    		@media #{$medium-up} {
		      margin: 0 -1.5rem;
		    }
    	}
    	.centre & {
    		h2{
    			text-align: center;
    			margin-bottom: vr(2);
    		}
    	}
    	.nosaltres & {
    		h2{
    			font-size: rem-calc(24);
    		}
    	}
    	.usuaris & {
    		h2{
    			text-align: center;
    			margin-bottom: vr(2);
    		}
    	}
	}


	&--grad {
		background-color: $primary-color;
		@media #{$medium-up} {
		    background-image: linear-gradient(236deg,$primary-color,$secondary-color);
		}
    	h2,h3,a,p{
    		color:#fff;
    	}
	}
	&--grad-green {
		background-color: #677f0e;
		color: #fff;
		@media #{$medium-up} {
		    background-image: linear-gradient(to bottom, #677f0e 1%, #e3f5a0 176%);
		    color: #000;
		}

    	.txt-intro {
    		font-size: rem-calc(20);
    		h2,h3,a,p {
    			color:#fff;
    		}
    	}
	}
	&--grad-2 {
		background-image: linear-gradient(to bottom, #c1c1c1, #f0f0f0);
    }
    &--gradnews {
		background-color: #CCE8F2;
		@media #{$medium-up} {
		    background-color: #CCE8F2;
		}
    	h2,h3,a,p{
    		color:#000;
    	}
    	.more {
    		color: #00528c;
    	}
    }
    &--gray {
		background-color: #f7f7f7;
    }
    &--introprof {

    	a {
    		text-shadow: none;
    		-webkit-box-shadow: none;
    		box-shadow: none;
    	}
    	h1 {
    		margin-top: 0 !important;
    	}
    	p {
    		text-align: center;
    	}
    	img {
    		margin-bottom: 0;
    	}
    	h2 {
    		color: #fff;
    		margin-bottom: rem-calc(10);
    		.colabora & {
    			@include light;
				font-size: rem-calc(35);
				margin-bottom: rem-calc(20);
				padding-bottom: rem-calc(5);
				position: relative;
				border-bottom: solid 1px #97a955;
				color: #000;
    		}
    		.usuaris & {
    			@include light;
				font-size: rem-calc(35);
				margin-bottom: rem-calc(20);
				padding-bottom: rem-calc(5);
				color: #000;
    		}
    	}
    	.colabora & {
    		text-align: center;
    		h3 {
    			font-size: rem-calc(16);
    		}
    	}
    	.usuaris & {
    		text-align: center;
    		h3 {
    			font-size: rem-calc(20);
    			color: #fff;
    			margin-bottom: 0;
    		}
    	}
    	.caixes-prof {
    		margin-top: rem-calc(30);
    		.colabora & {
    			margin-top: rem-calc(60);
    		}
    		.usuaris & {
    			margin-bottom: rem-calc(40);
    		}
            .icona {
                min-height: 75px;
            }
    		p {
    			text-align: left;
    			color: #fff;
    			margin: 0;
    		}
    		li {
    			a{
    				display: block;
    				@media #{$small-only} {
    					margin-bottom: vr(1.5);
    				}
    			}
    			.text {
    				span.btn {
    					border-color: #fff;
						color: #fff;
						margin-top: vr(1);
						@media #{$small-only} {
    						padding: vr(.3) vr(.6);
							@include typi('smallest');
    					}
    					@media #{$large-up} {
    						margin-top: 0;
    					}
    				}
    				padding: rem-calc(20 30);
    				.colabora & {
    					h3 {
    						color: #fff;
    						text-align: left;
    						font-size: rem-calc(24);
    						@include bold;
    						text-transform: none;
    						margin-top: rem-calc(20);
    						margin-bottom: rem-calc(10);
    					}
    				}
    				@media #{$medium-up} {
    					.subportada.nosaltres & {
    						min-height: 237px;
    					}
    					.professionals & {
    						min-height: 293px;
    					}
    				}
    				@media #{$large-up} {
    					.subportada.nosaltres & {
    						min-height: 115px;
    					}
    					.professionals & {
    						min-height: 155px;
    					}
						position: relative;
						span.btn {
							position: absolute;
							top: -130px;
						    left: 32%;
						    z-index: 10;
						    visibility: hidden;
  							opacity: 0;
  							transition: visibility 0s, opacity 0.5s linear;
  							.colabora & {
								left: 28%;
								position: relative;
								top: 10px;
								opacity: 1;
								visibility: visible;
								left: 0;
							}
						}
						&:after {
							content: '';
							display: block;
							height: 0;
							width: 100%;
							background-color: #00528c;
							position: absolute;
							left: 0;
							top: 0;
							transition: height .2s ease-in, top .2s ease-in;
							.colabora & {
								background-color: #677f0e;
							}
						}
						&:hover {
							&:after {
								height: 232px;
								top: -232px;
							}
							span {
								visibility: visible;
  								opacity: 1;
							}
						}
					}
    			}
    			&:nth-child(1) {
    			.text {
    				background-color: #00528c;
    			}
    			}
    			&:nth-child(2) {
	    			.text {
	    				background-color: #006ca4;
	    				@media #{$large-up} {
							&:after {
								background-color: #006ca4;
								.colabora & {
									background-color: #677f0e;
								}
								.usuaris & {
									background-color: #00528c;
								}
							}
						}
	    			}
    			}
	    		&:nth-child(3) {
	    			.text {
	    				background-color: #008ac0;
	    				@media #{$large-up} {
							&:after {
								background-color: #008ac0;
								.colabora & {
									background-color: #677f0e;
								}
								.usuaris & {
									background-color: #00528c;
								}
							}
						}
	    			}
	    		}
	    		.colabora & {
	    			.text {
	    				background-color: #677f0e;
	    			}
	    		}
	    		.usuaris & {
	    			.text {
	    				background-color: #00528c;
	    			}
	    		}
    		}
    	}
	}
    &--news{
    	padding: 0;
    	margin: vr(2) 0 vr(3);
    	.recerca & {
    		margin-bottom: 0;
    	}
    	.image{
    		max-height: 175px;
    		overflow: hidden;
    		margin-bottom: vr(.75)!important;

    	}
    	h2{
    		@include light;
    		text-transform: uppercase;
    		border-bottom: solid 1px #fff;
    		padding-bottom: vr(.5);
    		max-width: 350px;
    		margin-top: vr(1.5);
    		text-align: center;
    		@media #{$medium-up} {
    			text-align: left;
    			border-color: #008BC0;
    		}

    	}
    	h3{
    		text-transform: none;
    		font-size: rem-calc(22);
    		margin-bottom: vr(.5);

    		a{
    			&:hover{
    			text-decoration: none;
    			}
    		}

    	}
    	img{
    		@media #{$medium-up} {
    			padding-bottom: vr(1);
    		}    	}
    	p{
    		@include light;
    		&.data{
    			font-size: rem-calc(12);
    			margin-bottom: vr(.5);
    		}

    	}
    	@media #{$medium-up} {
	    	@supports (display: flex) {
		    	.newsroom {
				    display: flex;
				}

				.news {
				    flex: 58.33333%;
				}
				.events {
				    flex: 41.66667%;
				}
			}
		}
		.news,.events{
			&:after{
				content: '';
				position: absolute;
	    		bottom: -22px;
	    		left: 50%;
	    		width:48px;
	    		height: 48px;
	    		border-radius: 100%;
				margin-left:-24px;
				line-height: 48px;
				background: $primary-color;
				z-index: 2;
				@media #{$medium-up} {
					background: #006199;
					z-index: -1;
				}
			}
		}
		.events:after{
			background-color: #00668E;
			@media #{$medium-up} {
				background-color: #005782;
			}
		}
		@media #{$medium-up} {
			&.noagenda .news{
				a.more{
					left:100%;
				}
				&:after{
					left:100%;
				}
			}
		}
    	a{
    		@include undolink;
	    	&.more{
	    		position: absolute;
	    		bottom: -22px;
	    		left: 50%;
	    		width:48px;
	    		height: 48px;
	    		text-align: center;
	    		line-height: 48px;
	    		font-size: rem-calc(30);
				background-color: transparent;
				border-radius: 100%;
				margin-left:-24px;
				-webkit-font-smoothing: antialiased;
  				-moz-osx-font-smoothing: grayscale;
  				@include single-transition();
  				z-index: 3;
  				.recerca & {
  					background-color: #cce8f2;
  				}
  				@media #{$medium-up} {
  					//background: #006199;
  					//z-index: 1;
  				}
  				&:hover{
  					background-color:darken(#006199,10%);
  					text-decoration: none;
  					.recerca & {
  						background-color:darken(#cce8f2,7%);
  					}
  				}
	    	}
	    }
	    .news-item,.events-item{
    		padding-bottom: vr(2);
    		margin: 0 -.5rem;
    		@media #{$medium-up} {
		      max-width: 600px;
		      margin: 0 -1.5rem;
		      .medium-5{
    			padding-right: 0;
    		  }
		    }
    	}

    	.events{
    		background: rgba(0,0,0,.2);
    		padding-top: vr(1.25);
    		.recerca & {
    			background-color:darken(#cce8f2,7%);
    		}
    		@media #{$medium-up} {
    			padding:0 vr(1) vr(2);
    		}
    		h3{
    			margin-bottom: vr(1);
    		}

    		.date-box{
    			@include bold;
    			font-size: rem-calc(34);
    			color:#fff;
    			-webkit-font-smoothing: antialiased;
  				-moz-osx-font-smoothing: grayscale;
  				text-align: center;
  				.recerca & {
  					color: #00528c;
  				}
  				@media #{$medium-up} {
					text-align: left;
				}
    			span{
    				@include regular;
    				font-size: rem-calc(22);
    				text-transform: uppercase;
    				@media #{$medium-up} {
    					display: block;
    				}
    			}

    		}
    	}
    	.news--second{
    		display: none;
    		@media #{$medium-up} {
    			display: block;
				margin-top: 105px;
				&:after{
					content: none;
				}
			}
    	}

	}
	&--boxes{
		padding-bottom: 0;
		li {
		.item{
			position: relative;
			text-align: center;
			background-color: $primary-color;
			color:#fff;
			max-height: 300px;
			overflow: hidden;
			&:before {
				content: "";
			    display: block;
			    position: absolute;
			    top: 0;
			    bottom: 0;
			    left: 0;
			    right: 0;
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				margin: 0;
			}
			h2{
				text-transform: uppercase;
				@include bold;
				padding:0 1em;
				margin-top: 20%;
    			-webkit-transition: -webkit-transform 0.35s;
    			transition: transform 0.35s;
   	 			-webkit-transform: translate3d(0,40px,0);
    			transform: translate3d(0,60px,0);
    			color:#fff;

    		}
    		p {
			    margin: 1em 2em 0;
			    padding: 1em;
			    border: 1px solid #fff;
			    opacity: 0;
			    color:#fff;
			    @include typi('small');
			    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
			    transition: opacity 0.35s, transform 0.35s;
			    -webkit-transform: translate3d(0,20px,0) scale(1.1);
			    transform: translate3d(0,40px,0) scale(1.1);
			}
			a{
				position: absolute;
			    top: 0;
			    left: 0;
			    display: block;
			    width: 100%;
			    height: 100%;
			    -webkit-backface-visibility: hidden;
	    		backface-visibility: hidden;
	    		@include undolink;
	    		color:#fff;
	    		//background:rgba(#000,.5);
	    		&:hover{
	    			background:rgba(#000,.5);
	    			text-decoration: none;
	    			h2{
	    				-webkit-transform: translate3d(0,0,0);
    					transform: translate3d(0,0,0);
	    			}
	    			p{
	    				opacity: 1;
					    -webkit-transform: translate3d(0,0,0) scale(1);
					    transform: translate3d(0,0,0) scale(1);
	    			}
	    		}
    		}

			}
			&:nth-child(1) {
				.item {
	    			&:before {
					    background: rgba(245, 166, 35, 1);
				    	mix-blend-mode: multiply;
						}
	    			}
    			}
    		&:nth-child(2) {
    			.item {
	    			&:before {
					    background: rgba(196, 55, 64, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(3) {
    			.item {
	    			&:before {
					    background: rgba(162, 212, 109, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
		}
		.box-simple{
			a{
				height: 175px;
				background-color: $primary-color;
				position: relative;
				@include undolink;
				width: 100%;
				display: block;
				color: #fff;
				@include bold;
				@include typi(big);
				@include single-transition();
				text-align: center;
				&:hover{
					text-decoration: none;
					background-color: darken($primary-color,10%);

				}
				.wrapper-image{
					display: block;
					top:50%;
					left:50%;
					transform:translate(-50%,-50%);
					position: absolute;
					text-transform: uppercase;
					line-height: 25px;
					.image{
						height: 65px;
						position: relative;
						display: block;
						margin-bottom:vr(.75);
						width:100%;
					}
					img{
						position: absolute;
						max-width:75px;
						top:50%;
						left:50%;
						margin: 0;
						transform:translate(-50%,-50%);
					}

				}
			}
			&.box-simple--b{
				li{

					a{
						height: auto;
						padding: vr(2);
						display: block;
						background-color: #5a804c;
						&:hover{
							background-color: darken(#5a804c,10%);
						}
					}
				}
				@media #{$medium-up} {
					&.medium-block-grid-2{
						li{
							padding:0 vr(2);
						}
					}
				}

			}

		}
	}

	&--boxes2{
		padding-bottom: 0;
		.usuaris & {
			margin-top: -50px;
			padding-top: 0;
		}
		h2 {
			text-transform: uppercase;
			font-size: rem-calc(35);
			@include light;
			text-align: center;
		}
		p {
			text-align: center;
		}
		ul {
			margin-top: rem-calc(40);
		}
		li {
		.item-noimg {
			position: relative;
			text-align: center;
			color:#00528c;
			min-height: 193px;
			overflow: hidden;
			border: solid 1px #00528c;
			.usuaris &  {
					min-height: rem-calc(120);
				}
			h3{
				text-transform: uppercase;
				@include bold;
				padding:0 1em;
				margin-top: 5%;
    			-webkit-transition: -webkit-transform 0.35s;
    			transition: transform 0.35s;
   	 			-webkit-transform: translate3d(0,40px,0);
    			transform: translate3d(0,60px,0);
    			color:#00528c;
    			@media #{$medium-up} {
					margin-top: 5%;
				}
				.usuaris &  {
					font-size: rem-calc(18);
					margin-top: -18px;
					text-transform: none;
				}

    		}
    		a{
				position: absolute;
			    top: 0;
			    left: 0;
			    display: block;
			    width: 100%;
			    height: 100%;
			    -webkit-backface-visibility: hidden;
	    		backface-visibility: hidden;
	    		@include undolink;
	    		color:#fff;
	    		&:hover{
	    			background:rgba(#00528c,1);
	    			text-decoration: none;
	    			h3 {
	    				color: #fff;
	    			}
	    		}
    		}
		}
		.item{
			position: relative;
			text-align: center;
			background-color: $primary-color;
			color:#fff;
			max-height: 193px;
			overflow: hidden;
			&:before {
				content: "";
			    display: block;
			    position: absolute;
			    top: 0;
			    bottom: 0;
			    left: 0;
			    right: 0;
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				margin: 0;
			}
			h3{
				text-transform: uppercase;
				@include bold;
				padding:0 1em;
				margin-top: 7%;
    			-webkit-transition: -webkit-transform 0.35s;
    			transition: transform 0.35s;
   	 			-webkit-transform: translate3d(0,40px,0);
    			transform: translate3d(0,60px,0);
    			color:#fff;

    		}
			a{
				position: absolute;
			    top: 0;
			    left: 0;
			    display: block;
			    width: 100%;
			    height: 100%;
			    -webkit-backface-visibility: hidden;
	    		backface-visibility: hidden;
	    		@include undolink;
	    		color:#fff;
	    		//background:rgba(#000,.5);
	    		&:hover{
	    			background:rgba(#000,.5);
	    			text-decoration: none;
	    		}
    		}

			}
			&:nth-child(1) {
				.item {
	    			&:before {
					    background: rgba(0, 137, 194, 1);
				    	mix-blend-mode: multiply;
						}
	    			}
    			}
    		&:nth-child(2) {
    			.item {
	    			&:before {
					    background: rgba(245, 166, 35, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(3) {
    			.item {
	    			&:before {
					    background: rgba(196, 55, 64, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(4) {
    			.item {
	    			&:before {
					    background: rgba(162, 212, 109, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(5) {
				.item {
	    			&:before {
					    background: rgba(0, 82, 140, 1);
				    	mix-blend-mode: multiply;
						}
	    			}
    			}
    		&:nth-child(6) {
    			.item {
	    			&:before {
					    background: rgba(98, 171, 196, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(7) {
    			.item {
	    			&:before {
					    background: rgba(245, 166, 35, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(8) {
    			.item {
	    			&:before {
					    background: rgba(196, 55, 64, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(9) {
				.item {
	    			&:before {
					    background: rgba(162, 212, 109, 1);
				    	mix-blend-mode: multiply;
						}
	    			}
    			}
    		&:nth-child(10) {
    			.item {
	    			&:before {
					    background: rgba(0, 137, 194, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(11) {
    			.item {
	    			&:before {
					    background: rgba(245, 166, 35, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(12) {
    			.item {
	    			&:before {
					    background: rgba(196, 55, 64, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
		}
		.box-simple{
			a{
				height: 175px;
				background-color: $primary-color;
				position: relative;
				@include undolink;
				width: 100%;
				display: block;
				color: #fff;
				@include bold;
				@include typi(big);
				@include single-transition();
				text-align: center;
				&:hover{
					text-decoration: none;
					background-color: darken($primary-color,10%);

				}
				span{
					display: block;
					top:50%;
					left:50%;
					transform:translate(-50%,-50%);
					position: absolute;
					text-transform: uppercase;

					svg{
						display: block;
						margin: 0 auto vr(1);
					}

				}
			}
			&.box-simple--b{
				li{
					padding:0 vr(2);
					a{
						height: auto;
						padding: vr(2);
						display: block;
						background-color: #5a804c;
						&:hover{
							background-color: darken(#5a804c,10%);
						}
					}
				}

			}
		}
	}
	&--numbers{
		h2{
			text-align: center;
			@include light;
			text-transform: uppercase;
			margin-bottom: vr(2);
		}
		ul{
			li{
				@include regular;
				text-align: center;
				strong,b{
					@include light;
					font-size: rem-calc(30);
					display: block;
					@media #{$medium-up} {
						font-size: rem-calc(50);
					}
				}
				svg{
					display: block;

				}
			}
		}
		&.bg{
			background-color: $secondary-color;
			margin-bottom: vr(1.5);
			h2,li,p,a{
				color: #fff;

			}
		}
	}
	&--options{
		background-color: $bg-color;
		margin: vr(1) 0 vr(2);
		&.collapse{
			margin-bottom: 0;
		}
		.img{
		display: block;
		width:82px;
		height: 82px;
		margin-bottom: vr(.75);
		border-radius: 100%;
		background-color: #fff;
		position: relative;
			img{
			    display: block;
			    position: absolute;
			    top:50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}
		a{
			@include undolink;
			color: $secondary-color;
			text-decoration: underline;
		}
		p{
			margin-bottom: 0;
		}
		h3 {
			text-transform: none;
			a {
				text-transform: none;
				text-decoration: none;
				color: #000 !important;
			}
		}
		.multiple-centre & {
			margin: 0;
			padding:vr(1) 0;
			background-color: #fff;
			.img{
				background-color: $bg-color;
			}

		}
		@media #{$medium-up} {
			ul{
				li{
					padding-bottom: 0;
				}
			}
		}
		&.b{
			li{
				padding: 0 vr(2);
				margin-bottom: vr(2);
				@media #{$medium-up} {
					margin-bottom: 0;
				}
				h3{
					text-transform: none;
					margin-bottom: vr(.25);
				}
				p{
					@include typi('small');
				}
			}
		}

	}
	&--acnews{
		-webkit-font-smoothing: antialiased;
  		-moz-osx-font-smoothing: grayscale;
		padding-top: 0!important;
		h3{
			@include typi('big');
			text-transform: none;
			line-height: 1.25!important;
			margin-bottom: vr(.75);
			a{
				color: $body-font-color;
				@include undolink;
			}
		}
		.categoria{
			position: relative;
			margin-bottom: vr(.75);
			padding-bottom: vr(.75);
			&:after{
				content: '';
				width: 220px;
				height: 1px;
				background-color: #fff;
				position: absolute;
				bottom: 0;
				left: 50%;
				margin-left: -110px;
			}
			a{
				@include undolink;
				&:hover{
					text-decoration: none;
				}
			}
		}
		.data{
			@include bold;
			margin-bottom: vr(.75);
			@include typi('small');
		}
		.bg{
			background: $primary-color;
			color: #fff;
			margin: vr(1.5) 0;
			padding: vr(1.5) 0;
			p.descripcio{
				margin: 0;
			}
			h3{
				font-size: rem-calc(32);
				line-height: 1.2;
			}
			h3,p.categoria{
				a{
					color: #fff;
				}
			}
		}
		.altres-noticies{
			margin-bottom: vr(1.5);
			.categoria{
				position: relative;
				margin-bottom: vr(.5);
				padding-bottom: vr(.5);
				@include typi('small');
				&:after{
					background-color: $link-color;
					width: 100%;
					bottom: 0;
					left: 0;
					margin-left: 0;
				}
			}
			@media #{$small-only} {
				.medium-6:last-child{
					.categoria{
					 	margin-top: vr(2);
					 }
				}
			}
			.data{
				@include bold;
				margin-bottom: vr(.75);
				@include typi('small');
				color: $secondary-color;
			}
			img{
				margin-bottom: vr(.75)!important;
			}
		}
	}
	&--acagenda{
		background-color: $bg-color;
		padding-bottom: 0!important;
		-webkit-font-smoothing: antialiased;
  		-moz-osx-font-smoothing: grayscale;
  		.agenda & {
  			h1 {
  				margin-bottom: rem-calc(40) !important;
  			}
  		}
		h2{
			margin-bottom: vr(1.5)!important;
		}
		a{
			@include undolink;
		}
		h3{
			font-size: rem-calc(24);
			text-transform: none;
			line-height: 1.25;
			a{
				color: $body-font-color;
			}
		}
		p{
			margin-bottom: vr(.35);
		}
		.date-box{
			@include bold;
			font-size: rem-calc(34);
			color: $body-font-color;
			text-align: center;
			@media #{$medium-up} {
				text-align: left;
			}
			span{
				@include regular;
				font-size: rem-calc(22);
				text-transform: uppercase;
				padding-left: rem-calc(10);
				display: inline-block;
				@media #{$medium-up} {
					display: block;
					padding-left: 0;
				}
			}

		}
		.events-item {

    		padding: rem-calc(20);
    		border-bottom: 1px $primary-color solid;
    		@media #{$medium-up} {
    			min-height: 350px;
    			border-bottom: none;
    		}
    		@media #{$large-up} {
				padding: 0;
			}
    	}
    	.columns+.columns:last-child {
    		float: left;
    	}
	}
	&--acdestacats{
		padding-bottom: 0;
		.campanyes{
			margin: 0;
			margin-bottom: rem-calc(30);
			li{
				margin-bottom: rem-calc(20);
				padding-bottom: 0;
				a{
					display: block;
					background-color: $primary-color;
					margin-bottom: 0;
					color: #fff;
					@include undolink;
					padding: vr(1.5);
					@include single-transition();
					border: solid 2px #fff;
					@media #{$medium-up} {
					min-height: 330px;
					padding: vr(2);

					}
					&:hover{
						text-decoration: none;
						background-image: none;
						background-color: #fff;
						color: $body-font-color;
						border-color: $body-font-color;
						}
					}
					&:nth-child(1){
					a {
						background: #c05633;
						background-image: linear-gradient(-180deg, #FEC067 0%, #F77144 100%);
						&:hover{
						text-decoration: none;
						background-image: none;
						background-color: #fff;
						color: $body-font-color;
						border-color: $body-font-color;
						}
					}
					}
					&:nth-child(2){
						a {
							background: #C43740;
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
					&:nth-child(3){
						a {
						background: #60803f;
						background-image: linear-gradient(-180deg, #A2D46D 0%, #60803f 100%);
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
					&:nth-child(4){
						a {
							background: #414141;
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
					&:nth-child(5){
						a {
						background: #60803f;
						background-image: linear-gradient(-180deg, #A2D46D 0%, #60803f 100%);
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
					&:nth-child(6){
					a {
						background: #c05633;
						background-image: linear-gradient(-180deg, #FEC067 0%, #F77144 100%);
						&:hover{
						text-decoration: none;
						background-image: none;
						background-color: #fff;
						color: $body-font-color;
						border-color: $body-font-color;
						}
					}
					}
					&:nth-child(7){
						a {
							background: #C43740;
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
					&:nth-child(8){
						a {
						background: #60803f;
						background-image: linear-gradient(-180deg, #A2D46D 0%, #60803f 100%);
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
					&:nth-child(9){
						a {
							background: #414141;
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
					&:nth-child(10){
						a {
						background: #60803f;
						background-image: linear-gradient(-180deg, #A2D46D 0%, #60803f 100%);
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
					&:nth-child(11){
					a {
						background: #c05633;
						background-image: linear-gradient(-180deg, #FEC067 0%, #F77144 100%);
						&:hover{
						text-decoration: none;
						background-image: none;
						background-color: #fff;
						color: $body-font-color;
						border-color: $body-font-color;
						}
					}
					}
					&:nth-child(12){
						a {
							background: #C43740;
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
				}



			h3{
				font-size: rem-calc(24);
				text-transform:none;
			}
			p{
				margin-bottom: 0;
			}
		}

	}
	&--newsletter{
		margin-top: vr(1.5);
		background-color: #278194;
		color: #fff;
		a{
			@include undolink;
			color: #fff;
			text-decoration: underline;
		}
		.btn{
			background-color: #00678F;
			color: #fff;
			border-color:  #00678F;
			min-width: 150px;
			&:hover{
				background-color: #222;
			}
		}
		label.checkbox{
			text-transform: none!important;
			position: relative;
			padding-left: vr(1);

  			@include regular;
			input{
				position: absolute;
				left: 0;
				top: 2px;
				font-size: rem-calc(25);
			}
		}


		p{
			-webkit-font-smoothing: antialiased;
  			-moz-osx-font-smoothing: grayscale;
		}
	}
	&--noticies {
	text-align: center;
	padding:rem-calc(40 0 30);
		h2 {
			@include light;
			color: $primary-color;
			font-size: rem-calc(35);
			margin-bottom: vr(2) !important;
			padding-bottom: rem-calc(5);
			position: relative;
			border-bottom: solid 1px $primary-color;
		}
	}
}


.users-list-links{
	@include undolist;
	padding-left: vr(1.5);
	position: relative;
	@media #{$medium-up} {
		min-height:294px;
	}
	&:before {
      content: '';
      display: block;
      margin-bottom: vr(.5);
      font-size: 1rem;
      position: absolute;
      left: 0;
      padding: 5px 5px 15px;
      top: 0;
    }
    &:after{
    	content: '';
      display: block;
      border-left: solid 1px $tertiary-color;
      height: 100%;
      height: calc(100% - 32px);
      position: absolute;
      top:0;
      left:11px;
      margin-top:32px;
    }
	&--1{
		.site-menu & {
			@media #{$large-up} {
				//column-count: 2;
				min-height: 150px;
			}
		}
		&:before{
			@include sprite('ico_cor');
		}
	}
	&--2{
		.site-menu & {
			@media #{$large-up} {
				//column-count: 2;
				min-height: 150px;
			}
		}
		&:before{
			@include sprite('ico_info');
		}
	}
	&--3{
		.site-menu & {
			@media #{$medium-up} {
				//column-count: 3;
				min-height: 50px;

			}
			@media #{$large-up} {
				//column-count: 4;
				//margin-left: vr(2);
				//min-height: 50px;
				li{
					padding-bottom: vr(.25);
					a{
						font-size:rem-calc(18);
					}
				}

			}
		}
		&:before{
			@include sprite('ico_simbol');
		}
	}
	&--4{
		.site-menu & {
			@media #{$large-up} {
				//column-count: 2;
				min-height: 150px;
			}
		}
		&:before{
			@include sprite('ico-hospital');
		}
	}
	&--5{
		.site-menu & {
			@media #{$large-up} {
				//column-count: 2;
				min-height: 150px;
			}
		}
		&:before{
			@include sprite('ico-treballem');
		}
	}
	&--6{
		.site-menu & {
			@media #{$medium-up} {
				//column-count: 2;
				min-height: 50px;

			}
			/*@media #{$large-up} {
				li{
					padding-bottom: vr(.25);
					a{
						font-size:rem-calc(18);
					}
				}
			}*/
		}
		&:before{
			@include sprite('ico-doctor');
		}
	}
	&--7{
		.site-menu & {
			@media #{$medium-up} {
				min-height: 50px;
			}

		}
		&:before{
			@include sprite('ico_simbol');
		}
	}
	li{
		font-size:rem-calc(15);
		padding-bottom: 0;
		margin-bottom: vr(.6);
		float: none;

		a{
			@include undolink;
			@include regular;
			position: relative;
			padding: 0 0 3px;
			max-width: 250px;
			display: inline-block;
			&:after{
				display: block;
				position: absolute;
			    top: 100%;
			    left: 0;
			    width: 100%;
			    height: 1px;
			    background: darken($primary-color,15%);
			    content: '';
			    opacity: 0;
			    -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
			    -moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
			    transition: height 0.3s, opacity 0.3s, transform 0.3s;
			    -webkit-transform: translateY(-5px);
			    -moz-transform: translateY(-5px);
			    transform: translateY(-5px);
			}
			&:hover{
				text-decoration: none;
				color:darken($primary-color,15%);
				&:after{
					height: 2px;
				    opacity: 1;
				    -webkit-transform: translateY(0px);
				    -moz-transform: translateY(0px);
				    transform: translateY(0px);
				}
			}

		}
	}
	@media #{$medium-up} {
		&.medium-block-grid-2{
			li{
				float: left;
			}
		}
	}
	.site-menu & {
		min-height: 360px;
		li{
			font-size:rem-calc(16);

			&.destacat-submenu{
				display: none;
			}
		}
	}
	.centre & {
		min-height: 135px;
		li{
			padding: 0;
		}
		&.users-list-links--1{
				padding-right: vr(3);
			}
    }
    .usuaris & {

		li{
			a{
				max-width: none;
			}
		}
    }

}

.equip{
	margin: vr(1.5) 0;
	ul{
		@include block-grid(1);
		@media #{$medium-up} {
			@include block-grid(2);
		}

		li{
			position: relative;
			&:before{
				content: none;
			}
			&:after{
				content:'';
				background-color:  #979797;
				height: 1px;
				width:100%;
				width: calc(100% - 25px);
				bottom: .75rem;
				display: block;
				position: absolute;
				@media #{$medium-up} {
					width: calc(100% - 25px);
				}
			}
		}
	}
}
//aside
.aside{

  background-color:$bg-color;
  position: relative;
  margin:0 -15px;
  padding: vr(1.5) 15px;
  @media #{$medium-up} {
  	margin:0 10px;
  }
  @media #{$medium-up} {
    margin:vr(-2) -15px 0;
    padding: vr(2);
    .sidenav & {
    	padding: vr(2) vr(1);
    }

    .bg{
		position: absolute;
		top: vr(-3.1);
		right: -9px;
		background: $primary-color;
		opacity: .15;
		height: 108%;
		width: 100%;
		width: calc(100% + 9px);
	}
	.imatge & {
    	margin:vr(-2) 0;
    }
	.basic & {

		background-color:transparent;
	}
  }
  @media #{$xlarge-up} {
    margin-left: vr(1);
  }
  a{
    @include undolink;
    color: $secondary-color;
    position: relative;
  }
  .text{
  	position: relative;
  	p{
  		border-bottom:solid 1px #979797;
  		padding-bottom: vr(1);
  		@include regular;
  		&.boto {
  			text-align: center;
  			a{
  				background-color: transparent;
  				text-transform: none;
                max-width: 300px;
  				margin: 0 auto;
  				color:$primary-color;
  				&:hover{
  					background-color: $primary-color;
  					color: #fff;
  				}
  			}
  		}
  	}

  }

}

.seccions{
	.intro{
		margin-bottom: vr(2.5);
	}
	section{
		padding: vr(2.5) 0;
		margin: 0;
		a{
			@include undolink;
		}
		&:nth-child(even) {
			background-color: $bg-color;
			.icona{
				border-color: #fff;
			}
		}
		p{
			&.boto.big a{
				font-size:rem-calc(18);
				background-color: $primary-color;
				color: #fff;
				border-color: $primary-color;
			}

		}
		@media #{$medium-up} {
			h2{
				font-size:rem-calc(36);
				@include regular;
			}
			p{
				font-size:rem-calc(18);
				&.big{
					font-size:rem-calc(24);
				}
			}

			ul{
				li{
					font-size:rem-calc(18);

				}
			}
		}
		.equal{
			padding-top: 67px;
			padding-left: vr(3);
		}
		.icona{
			opacity:.5;
			width:50px;
			height: 50px;
			padding-top:8px;
			border-radius:100%;
			border:solid 5px #000;
			text-align:center;
			margin-bottom: vr(.3);
			svg{
				max-width: 25px;
				max-height: 25px;
			}
			@media #{$medium-up} {
				margin-bottom: vr(1);
				width:100px;
				height: 100px;
				padding-top:25px;
				svg{
					max-width: 50px;
					max-height: 50px;
				}

			}

		}
		ul.list-links-btn{
			margin: vr(1) 0 0;
			li{
				font-size:rem-calc(14);
				margin-bottom: 0;
			}
		}
	}
}
/*@media #{$medium-up} {
	.js {
		.enter-effect{
			//opacity: 0;
			visibility: hidden;
		}
		.anim{
			.enter-effect--1 {
				visibility: visible;
				opacity: 0;
			    animation: 0.8s ease 0s 1 normal forwards anim-up-fadeIn;
			    will-change: transform, opacity;
			    animation-fill-mode: both;
			    animation-delay: .3s;
			}
			.enter-effect--2 {
				visibility: visible;
				opacity: 0;
			    animation: 0.8s ease 0s 1 normal forwards anim-up-fadeIn;
			    will-change: transform, opacity;
			    animation-fill-mode: both;
			    animation-delay: .6s;
			}
		}

	}
}*/
@keyframes anim-up-fadeIn{
	0%
	{opacity:0;transform:translateY(50px)
	}
	100%
	{opacity:1;transform:translateY(0px)}
}
@keyframes anim-down-fadeIn{
    0%{
        opacity:0;
        transform:translateY(-50px)
    }
    100%{
        opacity:1;
        transform:translateY(0px)
    }
}
.urgencies {
	.bg--2 {
		padding: rem-calc(30);
	}
}
.colabora {
	.submenu-colabora {
		background-color: #677f0e;
		height: rem-calc(95);
		ul {
			list-style: none;
			padding-top: rem-calc(37);
			width: fit-content;
			margin: 0 -1em;
			@media only screen and (min-width: 360px){
				margin: 0 auto;
			}
			li {
				display: inline-block;
				padding-left: .5em;

				@media only screen and (min-width: 360px){
					padding-left: 1.2em;
					&:first-child{
						padding-left: 0;
					}
				}
				@media #{$medium-up} {
					padding-left: 2em;
				}
				&:before {
					content: none;
				}
				a {
					color: #fff;
					text-transform: uppercase;
					font-size: rem-calc(14);
					@include bold;
					text-shadow: none;
					box-shadow: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
.bg-green {
	// background-color: rgba(200, 236, 66, 0.5);
	background-color: #e4f6a4;
	text-align: center;
	padding:rem-calc(40 0 30);
	h2 {
		@include light;
		font-size: rem-calc(35);
		margin-bottom: rem-calc(20);
		padding-bottom: rem-calc(5);
		position: relative;
		border-bottom: solid 1px #97a955;
	}
	h3 {
		@include bold;
		font-size: rem-calc(16);
		margin: rem-calc(30 0 0);
	}
	p {
		font-size: rem-calc(16);
	}

}
.bg-green-2 {
	color: #fff;
	background-color: #778e21;
	text-align: center;
	padding:rem-calc(40 0 30);
	h2 {
		@include light;
		font-size: rem-calc(35);
		margin-bottom: rem-calc(20);
		padding-bottom: rem-calc(5);
		position: relative;
		border-bottom: solid 1px #fff;
	}
	h3 {
		@include bold;
		font-size: rem-calc(16);
		margin: rem-calc(30 0 0);
	}
	p {
		font-size: rem-calc(16);
	}
	a {
		color: #fff;
		@include undolink;
	}

}
.campanyes {
	.destacat {
		background: #c05633;
		background-image: linear-gradient(-180deg, #FEC067 0%, #F77144 100%);
	}
	.data {
		font-size: rem-calc(18);
	}
	.intro {
		@include bold;
		text-transform: uppercase;
		font-size: rem-calc(23);
	}
	.destacat {
		color: #fff;
	}
	.presentacio {
		padding-bottom: rem-calc(30);
		text-align: left;
		font-size: rem-calc(16);
		p, ul, h2, h3, h4 {
			text-align: left;
		}
	}
	.text-complementari {
		padding: rem-calc(40 0);
		font-size: rem-calc(16);
		p, ul, h2, h3, h4 {
			text-align: left;
		}
	}
	.activitats {
		padding-top: rem-calc(40);
		padding-bottom: rem-calc(40);
		color: #000;
		background-color: rgba(254, 192, 103, 0.4);
		font-size: rem-calc(16);
		h2, h3, h4 {
			text-align: left;
			color: #000;
		}
		p, ul {
			text-align: left;
			color: #000;
		}
		a {
			@include undolink;
			color: #c05633;
			@include bold;
		}
		ul {
			li {
				&:before {
					content: '';
					background-color: #c05633;
				}
			}
		}
	}
	.rel-docs {
		padding-top: rem-calc(30);
	}
}
.boxes-privats {
    .box-simple {
        li:last-child {
            a{
                height: 175px;
                padding: vr(2);
                display: block;
                background-color: #d8edf5;
                color:  $primary-color;
                &:hover{
                    background-color: darken(#d8edf5,10%);
                }
            }
        }
    }
}

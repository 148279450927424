.events-item{
  h3{
    margin-top: vr(.5);
    @media #{$medium-up} {
        margin-top: 0;
      }
  }
  .icon{
    position: relative;
    padding:0 vr(1);
    @include book;
    .recerca & {
      color: #00528c;
    }
    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
      }
      &--loc{
        &:before{
          @include sprite('ico-localitzacio-agenda');
          .recerca & {
            @include sprite('ico-loc-agenda-recerca');
          }
        }
      }
      &--time{
        &:before{
          top:3px;
          @include sprite('ico-hora-agenda');
          .recerca & {
            @include sprite('ico-hora-agenda-recerca');
          }
        }
      }
      &--cal{
        &:before{
          top:2px;
          left:-2px;
          @include sprite('ico-calendar');

        }
      }
      &--phone{
        &:before{
          top:-1px;
          left:1px;
          @include sprite('ico-tel-agenda');

        }
      }
  }
}



.calendari{
  background-color: #fff;
  padding: vr(1);
}
table.cal{
  caption{
    margin-bottom: vr(1);
    position: relative;
  }
  .arrow{
    position: absolute;
    a{
      display: block;
      @include undolink;
      text-align: center;
      width: 0;
      height: 0;
      margin-top: 3px;
      border-style: solid;
      color: $primary-color;
      span{
        @include visually-hidden;
      }
    }
    &.next{
      right: vr(.6);
      a{
          border-width: 8px 0 8px 13.9px;
          border-color: transparent transparent transparent $primary-color;
      }
    }
    &.previous{
      left: vr(.3);
      a{
          border-width: 8px 13.9px 8px 0;
          border-color: transparent $primary-color transparent transparent;
      }

    }
  }
  th{
    display: none;
  }
  th, td{
    padding: vr(.25) 0;
    text-align: center;
    border: none;
  }
  td{
    a{
      display: inline-block;
      width:26px;
      height: 26px;
      background-color: $primary-color;
      color:#fff;
      border-radius: 100%;
      padding-top: vr(.1);

      &:hover{
        background-color: $secondary-color;
        text-decoration: none;
      }
    }
  }
  td.notInMonth {
    opacity: .15;
  }
}
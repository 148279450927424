// mapa google
.map {
  text-align: center;
  img {
    width: 100%;
    margin-bottom: 0;
  }
}

// img dreta/esq
.img-right {
  img {
    @media #{$medium-up} {
      float: right;
      margin-left: vr(1);
    }
  }
}
.img-left {
  img {
    @media #{$medium-up} {
      float: left;
      margin-right: vr(1);
    }
  }
}
.memoria {
  position: relative;
  text-align: center;
  background-color: $primary-color;
  color: #fff;
  max-height: 470px;
  overflow: hidden;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 138, 191, 0.82);
    mix-blend-mode: multiply;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
  }
  h3 {
    text-transform: none;
    @include book;
    padding: rem-calc(0 10);
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 60px, 0);
    color: #fff;
    position: absolute;
    top: -40px;
    text-align: center;
    width: 100%;
    z-index: 1;
    font-size: rem-calc(20);
    line-height: 25px;
    @media #{$medium-up} {
      top: 50px;
      padding: rem-calc(0 50);
      font-size: rem-calc(30);
      line-height: 36px;
    }
    @media #{$large-up} {
      top: 100px;
      padding: rem-calc(0 250);
    }
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    @include undolink;
    color: #fff;
    //background:rgba(#000,.5);
    &:hover {
      background: rgba(#000, 0.5);
      text-decoration: none;
    }
  }
  span {
    background-color: #fff;
    top: 80px;
    position: relative;
    @media #{$medium-up} {
      top: 220px;
    }
    @media #{$large-up} {
      top: 300px;
    }
  }
}

.btns-bl {
  a {
    @include undolink;
    .bto-blanc {
      background-color: #fff;
      padding: rem-calc(20 40);
      @include single-transition;
      h3 {
        @include light;
        font-size: rem-calc(18);
        color: #000;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        margin-bottom: 0;
        @media #{$medium-up} {
          font-size: rem-calc(20);
        }
        @media #{$large-up} {
          font-size: rem-calc(24);
        }
        &:hover {
          text-decoration: none;
          color: #fff;
        }
      }
      &:hover {
        text-decoration: none;
        border-color: transparent;
        background-color: $primary-color;
        color: #fff;
      }
    }
    &:hover {
      text-decoration: none !important;
    }
  }
}
/*.back {
  @include typi('small');
  display: inline-block;
  margin-bottom: vr(1);
  @include undolink;
  @media #{$medium-up} {
    position: absolute;
    right: $column-gutter*.5;
    top: vr(.5);
    margin-bottom: 0;
  }
  &:before {
    content: '\2190';
  }
}*/

// imatge amb text overlay
/*.img-txt {
  @media #{$medium-up} {
    position: relative;
    margin-bottom: vr(2);
    &--last {
      margin-bottom: 0;
    }
    img {
      margin-bottom: 0;
    }
  }


}
.txt-overlay {
  @media #{$medium-up} {
    padding: vr(2) 0;
    background-color: #fff;
    background-color: rgba(#fff, .7);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    .info-block {
      margin-bottom: 0;
    }
  }
}


// imatge intro top
.intro-img {
  margin-bottom: 0;
}


.more {
  margin-top: vr(1.5);
}
*/
.ubicacio {
  border-bottom: solid 1px $primary-color;
  padding-bottom: vr(1);
  margin-bottom: vr(1);
  p {
    border-bottom: none !important;
    padding-bottom: 0 !important;
  }
}
.breadcrumb {
  background: darken($bg-color, 5%);
  padding: vr(0.25) 0;
  color: $secondary-color;
  @media #{$medium-up} {
    margin-top: vr(-1.5);
    margin-bottom: vr(1.5);
    padding: vr(0.5) 0;
  }
  .basic & {
    margin-bottom: vr(1.5);
    @media #{$medium-up} {
      margin-bottom: vr(3);
    }
  }
  a {
    @include undolink;
    color: $secondary-color;
    line-height: 1;
  }
  ol {
    @include typi("small");
    margin: 0;
    li {
      position: relative;
      &:after {
        content: "›";
        position: absolute;
        right: -10px;
        top: 0;
      }
      &:first-child {
        /*display: none;
        @media #{$medium-up} {
          display: inline-block;
        }*/
        display: inline-block;
      }
      &:last-child {
        > span {
          display: none;
          @media #{$medium-up} {
            display: inline-block;
          }
        }
        a span {
          display: inline-block;
        }

        //display: inline-block;
        a,
        span {
          @include bold;
          line-height: 1;
        }
        span {
          padding-left: 3px;
        }
        &:after {
          content: none;
        }
      }
    }
  }
}
.llista-vincles {
  margin: vr(2.5) 0;
  ul {
    @include undolist;
    li {
      border-bottom: solid 1px #979797;
      padding-bottom: vr(1.5);
      margin-bottom: vr(1.5);
      &:last-child {
        border: none;
      }
      h2 {
        font-size: rem-calc(18);
        margin-bottom: vr(0.3);
      }

      a.btn {
        font-size: rem-calc(14);
        text-transform: none;
        color: #9d6813;
        border-color: #9d6813;
        background-color: #fff;
        margin: 0;
        margin-right: vr(1);
        outline: 0;
        &:hover,
        &:focus {
          background-color: #9d6813;
          color: #fff;
        }
      }
    }
  }
}
ul.faqs {
  @include undolist;
  background-color: #f7f7f7;
  border-top: solid 3px $primary-color;
  padding: vr(1);
  li {
    //border-bottom: solid 1px #ccc;
    padding: vr(1);
    background-color: #fff;

    &:last-child {
      border: none;
    }
    > strong:first-child {
      display: block;
      margin-bottom: vr(0.5);
    }
  }
}
.sidenav--mobile {
  overflow: hidden;
  ul {
    text-align: left;
    border-top: solid 1px #fff;
    opacity: 0.95;
    margin: vr(0.5) 0 vr(2);
    > li > label {
      margin-bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      cursor: pointer;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      z-index: 2;
      display: block;
      &:after {
        content: "+";
        float: right;
        margin-top: 0;
        margin-right: 8px;
        -webkit-font-smoothing: antialiased;
        -webkit-transition: -webkit-transform 0.3s ease;
        transition: -webkit-transform 0.3s ease;
        transition: transform 0.3s ease;
        transition: transform 0.3s ease, -webkit-transform 0.3s ease;
      }
    }

    li {
      list-style: none;
      border-bottom: 1px solid #fff;
      a.simple {
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        @include book;
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: initial;
      }
      &:before {
        content: none;
      }

      ul {
        margin: 0;
        overflow: hidden;
        padding-top: 5px;
        padding-bottom: 16px;
        position: absolute;
        border: none;
        top: 100%;
        left: 0;
        width: 100%;
        visibility: hidden;
        z-index: -1;
        -webkit-transform: translateY(-100px);
        -ms-transform: translateY(-100px);
        transform: translateY(-100px);
        @include undolist;
        li {
          @include typi("small");
          border: none;
          margin: 0 vr(0.5) vr(0.5);
          a {
            @include undolink;
          }
        }
      }
      input[type="checkbox"] {
        display: none;
      }

      input[type="checkbox"]:checked ~ ul {
        //display: block
        position: static;
        visibility: visible;
        z-index: 1;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        -webkit-transition: -webkit-transform 300ms ease;
        transition: -webkit-transform 300ms ease;
        transition: transform 300ms ease;
        transition: transform 300ms ease, -webkit-transform 300ms ease;
      }
    }
    input[type="checkbox"]:checked + label:after {
      -webkit-transform: perspective(1px) rotate(45deg) scale(1.08);
      -ms-transform: perspective(1px) rotate(45deg) scale(1.08);
      transform: perspective(1px) rotate(45deg) scale(1.08);
    }
  }
}

/* 4. Simple tab interface */
.js .tab-interface {
  opacity: 0;
  @include single-transition();
  height: 200px;

  &.init {
    opacity: 1;
    height: auto;
  }
}

.list-block-2 {
  @include undolist;
  @media #{$medium-up} {
    @include block-grid(
      // This controls how many elements will be on each row of the block grid. Set this to whatever number you need, up to the max allowed in the variable.
        // Available options: 1-12 by default, and false.
        $per-row: 2,
      // This controls how much space is between each item in the block grid.
        // Use a variable or any pixel or em values.
        $spacing: 60px,
      // This controls whether or not base styles come through, set to false to leave out.
        $base-style: true
    );
  }

  li {
    > strong:first-child {
      display: block;
      font-size: rem-calc(20);
      margin-bottom: -10px;
    }
    padding-bottom: 24px;
    text-align: left;
  }
}

.list-block-3 {
  @include undolist;
  @media #{$medium-up} {
    @include block-grid(
      // This controls how many elements will be on each row of the block grid. Set this to whatever number you need, up to the max allowed in the variable.
        // Available options: 1-12 by default, and false.
        $per-row: 3,
      // This controls how much space is between each item in the block grid.
        // Use a variable or any pixel or em values.
        $spacing: 60px,
      // This controls whether or not base styles come through, set to false to leave out.
        $base-style: true
    );
  }

  li {
    > strong {
      display: block;
      font-size: rem-calc(20);
      margin-bottom: -10px;
    }
    padding-bottom: 24px;
    text-align: left;
  }
}

.list-block-4 {
  @include undolist;
  @media #{$medium-up} {
    @include block-grid(
      // This controls how many elements will be on each row of the block grid. Set this to whatever number you need, up to the max allowed in the variable.
        // Available options: 1-12 by default, and false.
        $per-row: 2,
      // This controls how much space is between each item in the block grid.
        // Use a variable or any pixel or em values.
        $spacing: 60px,
      // This controls whether or not base styles come through, set to false to leave out.
        $base-style: true
    );
  }
  @media #{$large-up} {
    @include block-grid(
      // This controls how many elements will be on each row of the block grid. Set this to whatever number you need, up to the max allowed in the variable.
        // Available options: 1-12 by default, and false.
        $per-row: 4,
      // This controls how much space is between each item in the block grid.
        // Use a variable or any pixel or em values.
        $spacing: 60px,
      // This controls whether or not base styles come through, set to false to leave out.
        $base-style: true
    );
  }
  li {
    > strong {
      display: block;
      font-size: rem-calc(20);
      margin-bottom: -10px;
    }
    padding-bottom: 24px;
    text-align: left;
  }
}
@supports (display: flex) {
  @media #{$medium-up} {
    .list-block-2 {
      &.list-links-btn,
      &.llista_cards {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        li {
          padding: rem-calc(10 30);
          flex: 1;
          flex-basis: 48%;
          max-width: 48%;
        }
      }
      &.llista_cards {
        margin: 0 -2% 0 0;
        li {
          margin-right: 2%;
        }
      }
      &.list-links-btn {
        margin-left: 0;
        li {
          padding-left: 0;
          max-width: 50%;
        }
      }
    }
    .list-block-3 {
      &.list-links-btn,
      &.llista_cards {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        li {
          padding: rem-calc(10 30);
          flex: 1;
          flex-basis: 31%;
          max-width: 31%;
        }
      }
      &.llista_cards {
        margin: 0 -2.33% 0 0;
        li {
          margin-right: 2.33%;
        }
      }
      &.list-links-btn {
        margin-left: 0;
        li {
          padding-left: 0;
          min-width: 33.33333%;
        }
      }
    }
    .list-block-4 {
      &.list-links-btn,
      &.llista_cards {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        li {
          padding: rem-calc(10 30);
          flex: 1;
          flex-basis: 23%;
          max-width: 23%;
        }
        &.llista_cards {
          margin: 0 -2% 0 0;
          li {
            margin-right: 2%;
          }
        }
        &.list-links-btn {
          margin-left: 0;

          li {
            padding-left: 0;
            min-width: 25%;
          }
        }
      }
    }
  }
}
.list-links {
  li {
    text-align: left;
    padding-left: vr(0.75);
    @media #{$medium-up} {
      padding-left: vr(1.85);
    }
    &:before {
      content: "";
      display: block;
      width: 0.4375em;
      height: 0.4375em;
      left: auto;
      top: 0.5em;
      margin-left: -15px;
      position: absolute;
      background-color: #00528c;
    }
    a {
      @include undolink;
      border-bottom: solid 1px $primary-color;
      //color: $body-font-color;
      &:hover {
        text-decoration: none;
        border-color: $body-font-color;
        color: $body-font-color;
      }
    }
  }
}

.list-links-btn {
  margin-bottom: vr(1.5);
  @include undolist;
  li {
    text-align: center;
    margin-bottom: vr(1);

    a {
      @include undolink;
      //box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px 1px rgba(0,0,0,.07);
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
      border: 1px solid #f0f0f0;
      text-transform: none;
      color: $primary-color;
      letter-spacing: 0.01em;
      margin: 0;
      padding: vr(1);
      border-radius: 4px;
      display: block;
      text-decoration: none;
      @include single-transition();
      background: #fff;
      text-align: left;
      position: relative;
      height: 100%;
      width: 100%;

      &:hover {
        box-shadow: 0 4px 4px rgba($primary-color, 0.24),
          0 0 4px rgba($primary-color, 0.12),
          0 6px 18px rgba(43, 133, 231, 0.12);
        transform: translate3d(0, -2px, 0);
        text-decoration: none;
      }
    }
  }
}

.llista_fons,
.fons {
  padding: vr(1);
  background-color: #f7f7f7;
  a {
    @include undolink;
  }
}
ul.llista_fons {
  margin-bottom: vr(1.5);
}
.llista_cards {
  @include undolist;
  margin-top: vr(1.5);
  li {
    margin-bottom: vr(1.5);
    padding: vr(1.5);
    //margin-right: vr(1.5);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid #f0f0f0;
    a {
      display: inline-block;
      @include undolink;
      @include regular;
      margin-bottom: vr(1);
      position: relative;
      &:hover {
        text-decoration: none;
        color: $secondary-color;
      }
      &.btn:hover {
        color: #fff;
      }
      &:after {
        bottom: -14px;
        content: "";
        height: 2px;
        left: 0;
        max-width: 60px;
        position: absolute;
        width: 100%;
        background-color: $primary-color;
      }
    }
    br + a {
      margin-top: vr(0.4);
      &:after {
        content: none;
      }
    }
    &.block {
      padding: 0;
      a {
        display: block;
        padding: vr(1);
        color: $body-font-color;
        height: 100%;
        outline: 0;
        &:hover {
          color: $primary-color;
        }
        &:after {
          content: none;
        }
        &:focus {
          background-color: #f7f7f7;
        }

        svg {
          position: absolute;
          bottom: 15px;
          right: 15px;
        }
      }
    }
    h2,
    h3 {
      font-size: rem-calc(20);
      text-transform: none;
    }
  }
  &--image {
    li {
      box-shadow: none;
      .text {
        padding: vr(1);
      }
      .image {
        height: 185px;
        width: 100%;
        overflow: hidden;
        &.b {
          height: 221px;
        }
        img {
          object-fit: cover;
          margin-bottom: 0;
          width: 100%;
        }
      }
      &.block {
        a {
          padding: 0;
        }
      }
    }
  }
  .memories & {
    a {
      font-size: rem-calc(20);
    }
    h2 {
      font-size: rem-calc(26);
    }
  }
}
.cards {
  li {
    text-align: left;
    a {
      position: relative;
      padding: vr(1) vr(1) vr(2.5);
      @media #{$medium-up} {
        min-height: 340px;
      }
    }
    img {
      max-height: 93px;
    }
    h3 {
      @include typi("normal");
      color: $body-font-color;
      margin-bottom: vr(0.25);
    }
    p {
      color: #767676;
      @include typi("small");
    }
    .icon-arrow {
      fill: $bg-color;
      position: absolute;
      right: vr(1);
      bottom: vr(1);
    }
  }
}
.list-links-btn2 {
  @include undolist;
  margin: vr(1);
  li {
    text-align: center;
    margin-bottom: vr(1);
    a {
      @include undolink;
      //box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px 1px rgba(0,0,0,.07);
      text-transform: none;
      color: $primary-color;
      letter-spacing: 0.01em;
      padding: vr(1);
      border-radius: 4px;
      display: block;
      text-decoration: none;
      @include single-transition();
      color: #fff;
      //min-height: 125px;
      @include bold;

      background-color: $primary-color;

      &:hover {
        box-shadow: 0 4px 4px rgba($secondary-color, 0.24),
          0 0 4px rgba($secondary-color, 0.12),
          0 6px 18px rgba(43, 133, 231, 0.12);
        background-color: $secondary-color;
        text-decoration: none;
      }
    }
  }
}
.llista_separadors {
  @include undolist;
  h2 {
    font-size: rem-calc(18);
    margin-bottom: vr(0.5);
  }
  p {
    font-size: rem-calc(14);
    max-width: 600px;
  }
  li {
    //border-bottom: solid 1px #efefef;
    margin-bottom: vr(1);
    padding-bottom: vr(1);
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #efefef;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
  &.medium-block-grid-2,
  &.list-block-2,
  &.list-block-3 {
    li {
      &:after {
        width: 92%;
      }
    }
  }
}
ul.equip {
  li {
    a {
      @include undolink;
      color: $body-font-color;
    }
  }
}
.emergencies {
  font-size: rem-calc(14);
  background-color: #bf0000;
  text-align: center;
  color: #fff;
  padding: vr(2);
  margin-bottom: vr(2);
  p {
    margin: 0;
  }
  .telefon {
    font-size: rem-calc(20);

    strong {
      font-size: rem-calc(50);
      line-height: 1.3;
      display: block;
    }
  }
}
.nivells-urgencia {
  ul {
    @include undolist;
    margin-bottom: vr(2);
    li {
      position: relative;
      padding-left: 40px;
      background-color: #f7f7f7;
      p {
        background-color: #f7f7f7;
        padding: vr(0.25) vr(1);
        strong {
          display: block;
          font-size: rem-calc(20);
        }
      }
      span {
        width: 40px;
        height: 100%;
        line-height: 40px;
        display: block;
        position: absolute;
        left: 0;
        background-color: #efefef;
        color: #000;
        text-align: center;
        font-size: rem-calc(20);
        &.nivell1 {
          background-color: #bf0000;
          color: #fff;
        }
        &.nivell2 {
          background-color: #fc5f4b;
        }
        &.nivell3 {
          background-color: #fd8a72;
        }
        &.nivell4 {
          background-color: #ffb2a3;
        }
        &.nivell5 {
          background-color: #ffd0c2;
        }
      }
    }
    &.temps-espera {
      li {
        padding-left: 100px;
        padding-right: 50px;
        span {
          width: 100px;
          line-height: 1.3;
          padding-top: vr(0.3);
          strong {
            display: block;
            font-size: rem-calc(25);
          }
          &.min {
            left: auto;
            right: 0;
            top: 0;
            line-height: 55px;
            width: 50px;
            @include bold;
          }
        }
        &.cap {
          padding-left: 0;
          padding-right: 125px;
          p {
            min-height: 60px;
            span.small {
              line-height: 1;
              position: relative;
              background-color: transparent;
              width: auto;
              text-align: left;
              padding: 0;
              @include typi("smaller");
            }
          }
          span.min {
            width: 125px;
            background-color: $bg-color;
          }
          &.simple {
            line-height: 55px;
          }
        }
      }
    }

    &.box{
        margin-top: vr(1);
        margin-left: 0;
        @media #{$medium-up} {
            margin-left: vr(1.5);
        }

        li {
            background-color:transparent;
            padding-left: 100px;
            padding-right: 0;
            min-height: 85px;
            margin-bottom: 20px;
            p {
                background-color:transparent;
                padding: 0 0 0 vr(0.4);
                font-size: rem-calc(14);

                strong {
                    display: block;
                    font-size: rem-calc(18);
                }
                }
            span {
                width: 90px;
                height: 100%;
                line-height: 90px;
            }
        }
    }
  }
}

.caixa_blava{
    background-color: rgba(0, 138, 192, 0.15);
    padding:vr(1.5);
    color: #00528c;

    span{
        strong{
            color: #000;
        }
    }
    .tel{
        strong{
            color: #000;
        }
    }
    strong{
        color: #00528c;
    }
}
.caixa_blanca{
    background-color: #fff;
    padding: vr(2) vr(2) vr(1) vr(2);
    margin-top: vr(1);
    color: #00528C;
    ul{
        padding:0 vr(.5);
    }
}

.calendar {
  li {
    border: solid 1px #ccc;
    padding: vr(1);
    margin: 0 0 1em;
    width: 125px;
    strong {
      display: block;
      font-size: rem-calc(18);
    }
  }
}
.tab-interface {
  margin: vr(2.5) 0 0;
  position: relative;
  @media #{$medium-up} {
    margin-bottom: vr(2.5);
  }
  .two-centre & {
    margin: vr(0.5) 0 0;
  }
  .urgencies & {
    margin: vr(0.5) 0 0;
  }

  h3 {
    text-transform: none;
  }
  ul.seccions {
    @include undolist;
    margin-bottom: vr(2);
    @media #{$medium-up} {
      margin-bottom: 0;
    }
    li {
      margin-bottom: vr(1);
      padding-bottom: 0;
      display: block;
      list-style: none;
      @media #{$medium-up} {
        display: inline-block;
        margin-bottom: 0;
      }
      a {
        background: $primary-color;
        color: #fff;
        padding: vr(1);
        display: block;
        margin-right: 0.25em;
        @include undolink;
        @include bold;
        width: 100%;
        .colabora & {
          background: #f0f0f0;
          color: #000;
          font-size: rem-calc(16);
          @include light;
          text-align: left;
        }
        @media #{$medium-up} {
          max-width: 230px;
          display: inline-block;
          .colabora & {
            max-width: 130px;
          }
          .colabora2 & {
            max-width: 365px;
          }
        }
        @media #{$large-up} {
          font-size: rem-calc(18);
          max-width: 365px;
          .colabora & {
            max-width: 160px;
          }
          .colabora2 & {
            max-width: 365px;
          }
        }
        &.b {
          font-size: rem-calc(16);
        }
      }
    }
  }
  .text {
    padding: vr(0.5) 0 vr(1.5);
    @media #{$medium-up} {
      padding: vr(2) 0 vr(1.5);
    }
    .two-centre & {
      padding: 0;
    }
    h2 {
      text-align: center;
      font-size: rem-calc(32);
      @include light;
      outline: 0;
      margin-bottom: vr(1);
    }
  }
  .urgencies & {
    .text {
      h2 {
        font-size: rem-calc(24);
        @media #{$medium-up} {
          font-size: rem-calc(32);
        }
      }
    }
  }
  .bg {
    padding: vr(2.5) 0 vr(1.5);
    &--1 {
      background: $bg-color;
    }
    &--2 {
      background: #fff;
    }
    .row {
      max-width: rem-calc(980);
    }

    .caixa_blava{
        padding: vr(2) vr(1);
        margin-bottom: vr(2);
    }
    &.white{
        background-color: #fff;
        color: #00528C;
    }
  }
  .circuit{
    display: flex;
    position: relative;
    margin-bottom: vr(2);
    span{
        font-weight: bold;
        color:#00528C;
    }
    > div{
        padding-left: vr(1);
        margin-left: vr(1);
        &::before{
            position: absolute;
            content: "";
            height: 100%;
            width: 1px;
            background-color: #000;
            top: 0;
            left: 32px;
        }
    }
    h4{
        font-weight: bold;
        color:#00528C;
        margin-top:0
    }
  }

  .faqs{
    padding: vr(3) 0 !important;
    .collapsible {
        background-color:transparent;
        .titleac{
            text-align: left;
            padding: 1.2rem;
            background-color: rgba(0, 138, 192, 0.15);
            button{
                text-align: left;
                max-width: 93%;
                @media #{$medium-up} {
                    max-width: 100%;
                }
                background-color:transparent;
                color: #00528C;
                &::after{
                  border-color: #00528c transparent transparent;
                }
                &::after{
                    right: -24px;
                    @media #{$medium-up} {
                        right: 12px;
                    }

                }
            }
        }
        div[aria-hidden="false"]{
            background-color:#fff;
            padding-left: vr(1);
            padding-right: vr(1);
        }
        div[aria-hidden="true"]{
            .titleac{
                background-color: #00528c;
            }
        }
    }

  }

  .seccions a[aria-selected],
  .seccions a[role="tab"]:focus {
    background: $bg-color;
    border-bottom: 0;
    position: relative;
    color: $secondary-color;
    outline: 0;
    .colabora & {
      // background: rgba(228, 252, 140, 0.5);
      background-color: #e4f6a4;
      color: #000;
    }
  }
  a {
    @include undolink;
  }
  a[role="tab"]:focus {
    background: $bg-color;
  }

  [role="tabpanel"] {
    //margin-top: vr(-2);
    margin-top: 0;
    padding: 1.5em 0;
    background: $bg-color;
    .colabora & {
      // background: rgba(228, 252, 140, 0.5);
      background-color: #e4f6a4;
    }
    @media #{$medium-up} {
      margin-top: 0;
    }
    .two-centre & {
      padding: 0;
    }

    //position: absolute;
    //top:0;
    //z-index: 5;
    //min-height: 100vh;
  }

  [role="tabpanel"][aria-hidden="true"] {
    display: none;
  }

  [role="tabpanel"]:focus {
    background: #eee;
    outline: thin dotted;
  }
}
.colabora {
  .tab-interface {
    #section2 {
      p, ul {
        text-align: left;
      }
    }
  }
}
div#gmap {
  width: 100%;
  height: 385px;
  text-align: center;
  font-size: 0.75em;
  line-height: 120%;
  padding: 0;
  overflow: hidden;
}
div#gmap img {
  max-width: none;
}

.message {
  background-color: $bg-color;
  padding: vr(0.5);
  max-height: 300px;
  margin: vr(4) 0 vr(3);
  @media #{$medium-up} {
    padding: vr(2);
  }
  > div {
    min-height: 300px;
    background-color: #fff;
    margin: vr(-3) auto 0;
    padding: vr(0.5);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
    @media #{$medium-up} {
      max-width: 70%;
      padding: vr(2);
      margin: vr(-5) auto 0;
    }
    h1 {
      font-size: rem-calc(18);
      padding-bottom: vr(0.5);
      margin: 0 0 vr(0.5);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:after {
        content: "";
        width: 60px;
        background-color: $primary-color;
        height: 3px;
        display: block;
        margin: vr(0.5) auto 0;
      }
    }
    p {
      font-size: rem-calc(20);
    }
    .btn {
      margin-bottom: 0;
    }
  }
}
.hg {
  @include undolist;
  li {
    img {
      display: block;
      margin-bottom: vr(0.8);
    }
    span {
      display: block;
      padding-left: vr(1.5);
    }
    h2 {
      @include typi("normal");
    }
    a {
      display: block;
      @include undolink;
      border-bottom: solid 1px #979797;
      padding-bottom: vr(1);

      position: relative;
      &:before {
        position: absolute;
        top: 50%;
        margin-top: -14px;
        right: 0;
        float: right;
        content: "\203A";
        color: $primary-color;
        opacity: 0;
        @include single-transition();
        font-size: rem-calc(24);
      }
      &:after {
        content: "";
        width: 0;
        height: 3px;
        background-color: transparent;
        position: absolute;
        bottom: -2px;
        left: 50%;
        @include single-transition();
      }
      &:hover {
        color: $primary-color;
        text-decoration: none;
        &:after {
          width: 100%;
          left: 0;
          background-color: $primary-color;
        }
        &:before {
          opacity: 1;
        }
      }
    }
    margin-bottom: vr(2);
    @media #{$large-up} {
      padding-right: vr(2.5);
    }
  }
}
.peu-foto {
  @include typi("smallest");
  margin-top: vr(-1.5);
}

.missatge {
  max-width: 550px;
  margin: 0 auto;
  .colabora & {
    text-align: center;
  }
  a {
    text-decoration: underline;
    .colabora & {
      text-decoration: none;
    }
  }
}
form + .missatge {
  margin-top: vr(3);
}

.dates-destacades {
  margin: 0 0 vr(2);
  @media #{$medium-up} {
    margin: vr(1) 0 vr(4);
  }
  .data-destacada {
    @media #{$medium-up} {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 3px;
        background-color: $primary-color;
        left: 50%;
        top: 0;
      }
    }
    &:nth-child(even) {
      background-color: $bg-color;
    }
    .columns {
      padding-top: vr(1);
      padding-bottom: 0;
      @media #{$medium-up} {
        padding-bottom: vr(1);
      }
    }
    .title {
      h3 {
        @include light;
        @include typi("bigger");

        margin: 0;

        @media #{$medium-up} {
          &:after {
            content: "";
            top: 50%;
            margin-top: -12px;
            width: 17px;
            height: 15px;
            position: absolute;
            border-style: solid;
          }
          padding: 0 vr(1.25);
        }
      }
    }

    .odd {
      @media #{$medium-up} {
        .title {
          text-align: right;
          h3 {
            &:after {
              right: 10px;
              border-width: 8.5px 0 8.5px 14.7px;
              border-color: transparent transparent transparent $primary-color;
            }
          }
        }
      }
    }
    .even {
      @media #{$medium-up} {
        text-align: right;
        .title {
          text-align: left;
          h3 {
            &:after {
              left: 10px;
              border-width: 8.5px 14.7px 8.5px 0;
              border-color: transparent $primary-color transparent transparent;
            }
          }
        }
        .text {
          text-align: right;
        }
      }
    }
  }
}
svg.fletxa {
  fill: $primary-color;
  width: 18px;
  height: 18px;
}
.destacat {
  background-color: #c4e4f0;
  padding: rem-calc(30);
  font-size: rem-calc(18);
  a {
    text-shadow: none;
  }
  .events &,
  .campanyes & {
    p {
      margin-bottom: rem-calc(5);
    }
    margin-bottom: rem-calc(50);
    .data {
      margin-top: 0;
      font-size: rem-calc(20);
      @include bold;
    }
  }
}
.num-destacat {
  font-size: rem-calc(40) !important;
}

.js {
  .llista-vincles .prova {
    display: none;
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.colaboradors {
  margin-bottom: rem-calc(50);

  @media #{$medium-up} {
    //column-count: 2;
  }
  @media #{$large-up} {
    margin-top: rem-calc(30) !important;
  }
  .colab {
    text-align: left;
    &:before {
      content: none;
    }
    &.first {
      @media #{$large-up} {
        //padding-top: rem-calc(90);
      }
    }
    a {
      color: #000;
      box-shadow: none;
      text-shadow: none;
      border-bottom: solid 1px #979797;
      display: block;
      margin: rem-calc(0 0 15);
      padding-bottom: rem-calc(10);
      h4 {
        margin-top: 0;
        text-transform: none;
        font-size: rem-calc(16);
        @include bold;
        margin-bottom: 0;
      }
      p {
        font-size: rem-calc(14);
        margin-bottom: 0;
        text-align: left;
      }
    }

    .effect-sub {
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      position: relative;
      overflow: hidden;
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 100%;
        bottom: 0;
        background: #8cac17;
        height: 4px;
        -webkit-transition-property: right;
        transition-property: right;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
      h4 {
        padding-top: rem-calc(10);
      }
    }
    .effect-sub:hover:before,
    .effect-sub:focus:before,
    .effect-sub:active:before {
      right: 0;
    }
  }
}
#colaboracions {
  padding: rem-calc(50 0 90);
}
.fitxa-news {
  //@include light;
  //font-size: rem-calc(18);
  text-align: left;
}
.share {
  //display: none;

  display: block;
  text-align: center;
  margin-left: 0;
  margin-bottom: vr(2);
  margin-top: rem-calc(30);
  border-top: 1px solid #ccc;
  padding-top: rem-calc(20);
  h2 {
    text-transform: uppercase;
    font-size: rem-calc(20);
    @include light;
  }
  ul {
    list-style: none;
    margin: 0;
  }
  li {
    padding-left: 0;
    display: inline-block;
    margin: 0 vr(0.5);
    vertical-align: middle;
    &:before {
      display: none;
    }
  }
  a {
    display: inline-block;
    opacity: 1;
    transition: opacity 0.1s;
    border: 0;
    text-shadow: none;
    box-shadow: none;
    &:hover {
      opacity: 0.8;
    }
  }
}
.intro {
  font-size: rem-calc(18);
}
.fitxa-news {
  .data {
    margin-top: rem-calc(50);
  }
}
.rel-docs {
  h2 {
    @include light;
    text-transform: uppercase;
    font-size: rem-calc(18);
    border-bottom: 1px solid $primary-color;
    padding-bottom: rem-calc(10);
    .campanyes & {
      border-bottom: 1px solid #c05633 !important;
    }
  }
  ul {
    li {
      background-color: #c4e4f0;
      padding: rem-calc(10 30);
      position: relative;
      .campanyes & {
        background-color: rgba(254, 192, 103, 0.4);
        text-align: left;
        padding: rem-calc(10 35);
      }
      &:before {
        content: "";
        background-image: url("img/ico-link.svg");
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        background-color: transparent;
        top: 10px;
        left: 7px;
        .campanyes & {
          background-image: url("img/ico-link-taronja.svg");
          left: 10px;
        }
      }
      a {
        @include undolink;
        color: #00528c;
        .campanyes & {
          color: #c05633;
          @include bold;
        }
      }
    }
  }
}
.no-js {
  #form-proves-mediques,
  #sort-news {
    display: none;
  }
}
.agenda {
  .alert-box {
    background-color: #fff;
    text-align: center;
    font-size: rem-calc(18);
    padding: rem-calc(20);
    margin-top: rem-calc(20);
    border-radius: 8px;
  }
}
#list {
  min-height: 350px;
}
.loading {
  position: absolute;
  top: 2%;
  left: 50%;
  margin-left: -19px;
}
.filter-bar {
  background-color: #f7f7f7;
  margin-bottom: vr(1);
  padding: vr(1) 0;
}
.professional {
  .fons {
    margin-bottom: vr(2);
    h1 {
      margin-bottom: vr(0.5);
    }
    p {
      margin-bottom: vr(0.25);
    }
  }
  p.centre {
    margin-bottom: 0;
    @include bold;
  }
}
.jobs {
  .llista_separadors {
    margin-top: vr(1);

    margin-bottom: vr(2);
    border-top: solid 1px #efefef;
    h2,
    h3 {
      margin-bottom: vr(0.5);
      color: $secondary-color;
      text-transform: none;
      @include regular;
      @media #{$medium-up} {
        font-size: rem-calc(20);
      }
    }

    li {
      p {
        @include typi("small");
        color: $body-font-color;
        margin: vr(0.25);
      }
      .label {
        display: inline-block;
        font-size: rem-calc(12);
        border-radius: 6px;
        margin: vr(0.75) auto 0;
        border: solid 1px $secondary-color;
        color: $secondary-color;
        padding: vr(0.1) vr(0.5);
      }
    }
    a {
      padding: vr(1);
      padding-left: vr(2.5);
      margin: 0;
      display: block;
      outline: 0;
      @include undolink;
      @include single-transition();
      &:focus {
        background-color: #f7f7f7;
      }
      &:hover {
        text-decoration: none;
        background-color: #f7f7f7;
        svg {
          opacity: 1;
        }
        p {
          color: $body-font-color;
        }
      }
    }
    svg {
      position: absolute;
      left: vr(0.5);
      top: vr(0.85);
      fill: $primary-color;
      opacity: 0.5;
      @include single-transition();
    }
  }
  .jobs-list {
    .llista_separadors {
      border-top: solid 1px #fff;
    }
  }
  .estat {
    padding: vr(0.1) vr(0.5);
    text-align: center;
    display: inline-block;
    border-radius: 6px;
    margin: 0 0 vr(1);
    @include typi("small");
  }
  .oberta {
    color: #00a550;
    border: solid 1px #00a550;
  }
  .finalitzada {
    color: #e50000;
    border: solid 1px #e50000;
  }
}
.job {
  &.apply {
    .form26-elem317,
    .form26-elem318 {
      display: none;
    }
  }
}

.proves-mediques {
  background-color: #f9f9f9;
  padding: vr(1);
  margin: vr(1) 0 vr(2);
  h2 {
    font-size: rem-calc(18);
  }
  .llista-vincles {
    margin: 0;
    li.prova {
      padding-top: vr(1);
    }
  }
}
div.separacio {
  margin-top: vr(1.5);
  padding-top: vr(1.5);
  position: relative;
  p {
    margin-bottom: vr(0.25);
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: 4px;
    background-color: $primary-color;
    width: 80px;
    left: 50%;
    margin-left: -40px;
  }
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-img {
  a {
    display: block;
    position: relative;
    max-width: 498px;
    outline: 0;
    border: solid 2px transparent;
    margin-bottom: vr(1);
    img {
      margin: 0;
    }
    &:focus {
      border-color: #fff;
    }
    span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      padding: vr(0.5);

      background: rgba(0, 0, 0, 0.7);
    }
  }
  p {
    padding: 0 vr(2) 0 vr(1);
    @media #{$medium-up} {
      font-size: rem-calc(30) !important;
      line-height: 1.3;
    }
  }
}
.quadre-temps-espera {
  background-color: $bg-color;
  padding: vr(1);
  margin: vr(2) 0;
  p {
    margin: 0;
    &.big {
      @include bold;
      font-size: rem-calc(30);
    }
  }
}
#colabora {
  img {
    margin-bottom: 0;
  }
  h3 {
    margin-top: vr(0.5);
  }
}
.fons.infografies {
  margin-bottom: vr(0.5);
  h1 {
    margin-bottom: 0;
  }
}
.mfp-figure {
  figcaption {
    padding: 0;
  }
}
.com-contactar {
  .block-list.abs ul li:first-child a {
    font-size: rem-calc(20);
  }
  .llistat {
    margin-bottom: vr(2);
    li {
      .item-noimg {
        min-height: 100px;
      }
      a {
        color: $secondary-color;
        &:hover {
          color: #fff;
          h2 {
            color: #fff;
          }
        }
      }
      h2 {
        color: $secondary-color;
        text-transform: none;
        font-size: rem-calc(20);
        @include bold;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: vr(0.5);
      }
    }
  }
  .block-list.destacada {
    margin-bottom: vr(2);
    a {
      background-color: $secondary-color;
      display: block;

      @include undolink;
      color: #fff;
      @include single-transition();
      .text {
        padding: 0 vr(1);
        min-height: 70px;
        h2 {
          font-size: rem-calc(20);
        }
      }
      &:hover {
        text-decoration: none;
        background-color: $primary-color;
      }
    }
  }
}
.red-text {
  @include typi("smaller");
  color: #D04848;
  font-style: italic;
}
.colabora2 {
  .guardiola {
    img {
      margin-bottom: 0;
      h4 {
        margin-top: rem-calc(15);
      }
    }
    .list-links-btn2 {
      li {
        a {
          background-color: #677f0f;
        }
      }
    }
  }
  .btns-colabora {
    li {

      a {
        display: table;
        width: 100%;
        min-height: rem-calc(130);
        color: #677f0f !important;
        background-color: transparent !important;
        border: 2px solid #677f0f !important;
        h3 {
          margin-bottom: 0;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
        }
      }
    }
  }
}
.contacta-mutual {
    background-color:$bg-color;
    padding: vr(1) vr(1.5);
    max-height: 300px;
    @media #{$medium-up} {
        max-height: auto;
    }
    @media #{$large-up} {
        max-height: 300px;
    }
    p{
        border-bottom:solid 1px #979797;
        padding-bottom: vr(1);
        @include regular;
        &.boto {
            text-align: center;
            margin-bottom: 0;
            padding-bottom: 0;
            a{
                background-color: transparent;
                max-width: 300px;
                margin: 0 auto;
                color:$primary-color;
                &:hover{
                    background-color: $primary-color;
                    color: #fff;
                }
            }
        }
        &:last-child {
            border-bottom: none;
        }
    }
}
.logos-list {
    font-size: 0;
    li {
        padding: 1.5rem .5rem;
        margin-bottom: 0;
        vertical-align: middle;
        display: inline-block;
        float: none;
        font-size: 1rem;
        a {
            text-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }
    img {
        margin-bottom: 0;
    }
}
h1.title-mutua {
    margin-bottom: 0;
}
.logo-mutua {
    margin-top: 20px;
}
.logo-privat {
    display: none;
    @media #{$medium-up} {
        max-width: 250px;
        margin: 0 auto;
        margin-bottom: 40px;
        display: block;
    }
}
.ico-serveis {
    display: block;
    margin: 0 auto;
    margin-top: 45px;
}
.img-top--serveis-privats {
    min-height: 300px !important;
    h1 {
        font-size: 1.5rem;
        @media #{$medium-up} {
            font-size: 3rem;
        }
    }
  }
  .title-serveis-privats-f {
    top: 65% !important;
  }

.video {
  margin-bottom: vr(1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}
.cols {
  @media screen and (min-width: 20em) {
    display: flex;
    flex-wrap: wrap;
  }
  div {
    border-left: 1px solid #000;
    padding: 0 vr(1);
    margin-bottom: vr(1);
    @media screen and (min-width: 20em) {
      flex-basis: 50%;
    }
    @media #{$large-up} {
      flex-basis: 25%;
      margin-bottom: 0;
    }
  }
  p {
    font-size: rem-calc(14);
  }
}

.icon-container {
  display: flex;
  margin-bottom: vr(1);
  justify-content: center;
  div {
    margin-left: vr(1);
  }
  h3 {
    margin-top: 0 !important;
  }
  p {
    margin-bottom: 0;
  }
}

// projectes
.colabora .projectes {
  margin: vr(2) 0;
  > div {
    position: relative;
    @media #{$medium-up} {
      margin-bottom: vr(1);
    }
  }
  div div {
    background-color: #8cac17;
    text-align: center;
    padding: vr(0.5);
    @media #{$medium-up} {
      padding: vr(1) vr(2);
    }
  }
  img {
    margin-bottom: 0;
  }
  h3 {
    color: #fff;
    text-transform: none;
    font-weight: bold;
    font-size: rem-calc(18);
    margin-bottom: vr(0.5);
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    @media #{$large-up} {
      font-size: rem-calc(22);
    }
  }
  .btn {
    margin-bottom: 0;
    position: static;
    background-color: transparent;
    &:hover {
      border-color: #fff;
      background-color: #677f0e;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
    }
  }
}

// nav
.colabora-nav {
  color: #fff;
  background-color: #778e21;
  padding: vr(1) 0;
  text-align: center;
  @media #{$medium-up} {
    margin-top: vr(-1.5);
    padding: vr(1.5) 0;
  }
  @media #{$large-up} {
    padding-top: vr(2);
  }
  a {
    @include undolink;
    color: #fff;
  }
  .apartat {
    display: block;
    text-transform: uppercase;
    font-size: rem-calc(30);
    @include bold;
    margin: 0 auto vr(1);
    letter-spacing: normal;
  }
  ul {
    font-size: 0;
    margin-bottom: 0;
    a {
      display: block;
      border-radius: 18.5px;
      background-color: transparent;
      padding: vr(0.25) vr(0.5);
      transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
    }
  }
  li {
    font-size: rem-calc(14);
    text-transform: uppercase;
    @include bold;
    padding: 0;
    display: inline-block;
    margin: 0 vr(0.5) vr(0.25);
    letter-spacing: 0.88px;
    @media #{$medium-up} {
      margin-bottom: 0;
    }
    &:before {
      display: none;
    }
  }
  .active a,
  li a:hover {
    color: #677f0d;
    background-color: #f1fdc6;
    border-bottom: 0;
    text-decoration: none;
  }
}
.colabora-header {
  background-color: #8cac17;
  text-align: center;
  position: relative;
  padding: vr(1) 0;
  h2,
  p {
    text-transform: none;
    color: #fff;
    @include light;
    letter-spacing: normal;
    font-size: rem-calc(30);
    margin: 0;
    a {
      @include undolink;
      text-transform: none;
      color: #fff;
      @include light;
      letter-spacing: normal;
      font-size: rem-calc(30);
      margin: 0;
    }
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: rem-calc(70);
    opacity: 0.3;
    background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
  }
}

.projectes-intro {
  margin-bottom: vr(1);
  p {
    @include light;
    font-size: rem-calc(18);
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
  }
}

// Enllaços relacionats
.colabora-rel {
  a {
    @include undolink;
    display: block;
    border: 1px solid #677f0d;
    border-radius: 6px;
    padding: vr(1) vr(0.5);
    text-align: center;
    color: #000;
    transition: border-radius 0.15s ease-in-out;
    &:hover {
      border-radius: 0;
    }
  }
}

// donacions
.donacions-list {
  margin-top: vr(2);
  text-align: left;
  li {
    padding-bottom: 0 !important;
  }
  h3 {
    text-transform: none;
    @include bold;
    font-size: rem-calc(16);
    margin: 0;
  }
  p {
    text-align: left;
    font-size: rem-calc(14);
    padding-bottom: vr(0.5);
    border-bottom: 1px solid #97a955;
  }
}

// bloc notícies
.noticia {
  text-align: left;
  margin-bottom: vr(1.5) !important;
  img {
    margin-bottom: vr(0.5) !important;
  }
  h3 {
    text-transform: none;
    margin-bottom: vr(0.25);
    line-height: 1.2;
    a {
      @include undolink;
    }
  }
  p {
    line-height: 1.4;
  }
  > div:first-child {
    @media #{$medium-up} {
      padding-right: 0;
    }
  }
}

.mes {
  display: inline-block;
  background-color: $primary-color;
  border-radius: 50%;
  color: #fff;
  @include undolink;
  width: rem-calc(48);
  height: rem-calc(48);
  text-align: center;
  transition: background-color 0.15s ease-in-out;
  @media #{$medium-up} {
    margin-top: vr(1);
    margin-bottom: vr(2) !important;
  }
  &:before {
    content: "+";
    @include light;
    font-size: rem-calc(30);
  }
  &:hover {
    text-decoration: none;
    background-color: lighten($primary-color, 5%);
  }
}

// fitxa
.projecte-fitxa {
  padding-bottom: 0;
  h1 {
    color: #677f0d;
    @include bold;
    font-size: rem-calc(20);
    text-transform: none;
    letter-spacing: normal;
    margin-bottom: 0;
    @media #{$medium-up} {
      font-size: rem-calc(35);
    }
    @media #{$large-up} {
      font-size: rem-calc(42);
    }
  }
  h2 {
    @include light;
    text-align: center;
    margin-bottom: vr(2) !important;
  }
  img {
    margin-bottom: 0;
  }
  .text {
    padding-top: vr(2);
    p {
      font-size: rem-calc(18);
      @include light;
    }
  }
  .container {
    background-color: #e4f6a4;
    padding: vr(2) 0 vr(2) vr(2);
    position: relative;
    &:after {
      content: "";
      display: block;
      width: 100%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      left: 100%;
      background-color: #e4f6a4;
    }
    h2 {
      text-align: left;
    }
    a {
      @include undolink;
      color: #677f0d;
    }
  }
  .sub-container {
    padding: vr(1) 0 0;
    margin-bottom: vr(1);
    border: solid #677f0d;
    border-width: 1px 0;
  }
}

.btn,
p.boto a {
  @include typi("btn");
  @include undolink;
  @include bold;
  @include single-transition;
  display: inline-block;
  text-decoration: none;
  margin-bottom: vr(1);
  padding: vr(0.5) vr(1);
  text-transform: uppercase;
  text-align: center;
  background-color: transparent;
  border: 1px solid;
  border-radius: 4px;
  letter-spacing: 1.4px;
  color: $primary-color;
  position: relative;

  .slider & {
    color: #fff;
  }
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    border-color: transparent;
    //outline: 2px solid;
    background-color: $primary-color;
    color: #fff;
    .colabora & {
      background-color: #778e23;
    }
  }

  &.icon {
    padding-left: 38px;
    svg {
      fill: $primary-color;
      position: absolute;
      top: 50%;
      margin-top: -12px;
      left: 6px;
    }
    &:hover {
      svg {
        fill: #fff;
        animation: MoveUpDown 1s linear 1;
      }
    }
  }

  &--agenda {
    color: #ec6e24;
    border-color: #f39a00;
  }

  &--neg {
    color: #fff;
    border-color: #fff;
  }

  &--solid {
    background-color: $primary-color;
    color: #fff;
    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }

  &--small {
    @include bold;
    @include typi("smaller");
    padding: vr(0.25) vr(0.75);
    background-color: #000;
    color: #fff;
    text-transform: none;
    letter-spacing: 0;
    border-radius: 0;
    &:hover,
    &:focus,
    &:active {
      background-color: #fff;
      color: #000;
    }
    .agenda-item & {
      margin-bottom: 0;
      border: 0;
    }
  }

  &--large {
    @media #{$large-up} {
      @include typi("big");
    }
  }
  &--destacat {
    background-color: #8f100f;
    border-color: #8f100f;
    color: #fff;
    text-decoration: none;
    font-size: rem-calc(14);
    &:hover {
      background-color: darken(#8f100f, 10%);
      text-decoration: none !important;
    }
  }
  &--round {
    background-color: #8cac17 !important;
    border-radius: 26px;
    @include bold;
    font-size: rem-calc(14);
    padding: vr(0.6) vr(2);
    &:hover,
    &:focus {
      background-color: #677f0e !important;
    }
  }
  &--icon {
    border: 1px solid #677f0d;
    background-color: transparent !important;
    color: #677f0d !important;
    &:hover {
      border-color: #677f0d;
      background-color: #e4f6a4 !important;
    }
  }
  &--pdf {
    border: 1px solid #677f0d;
    background-color: #fff !important;
    color: #677f0d !important;
    &:hover {
      border-color: #677f0d;
      background-color: #e4f6a4 !important;
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
    &::before {
      content: "";
      @include sprite("ico-pdf");
      margin-right: vr(0.5);
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.btn-cercar {
  margin: 0 auto;
  margin-top: rem-calc(20);
}
.colabora .main-content {
  .btn,
  p.boto a {
    color: #fff;
    background-color: #677f0e;
    &:hover {
      background-color: darken(#677f0e, 15%);
    }
  }
}
.boto-gran {
  width: 100%;
  display: block;
  text-align: center;
  border: 1px solid $primary-color;
  background-color: transparent;
  letter-spacing: 1.4px;
  color: $primary-color;
  font-size: rem-calc(15);
  text-transform: uppercase;
  @include undolink;
  @include bold;
  @include single-transition;
  margin-top: rem-calc(20);
  padding: rem-calc(20 0);
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    border-color: transparent;
    background-color: $primary-color;
    color: #fff;
  }
}
@keyframes MoveUpDown {
  0%,
  100% {
    top: 50%;
  }
  25% {
    top: 40%;
  }
  50% {
    top: 50%;
  }
  75% {
    top: 60%;
  }
}

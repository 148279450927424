.covid {
  .section--quick-links h2.tit-covid {
    margin-bottom: rem-calc(15);
  }
  .sub-covid {
    margin-bottom: rem-calc(30);
  }
  .img-top {
    position: relative;
  }
  .covid-especial {
    position: relative;
    padding-top: rem-calc(100);
    @media #{$medium-up} {
      padding-top: 0;
      position: absolute; 
      bottom: rem-calc(-100);
      left: 3%;
    }
    @media #{$large-up} {
      left: 15%;
    }
     img {
      width: 100px;
      @media #{$medium-up} {
        width: 165px;
      }
    }
  }
  .covid-especial-basic {   
      padding-top: 0;
      position: absolute; 
      bottom: rem-calc(-50);
    
    @media #{$large-up} {
      left: 3%;
    }
    @media #{$xlarge-up} {
      left: 15%;
    }
    img {
      width: 100px;
      @media #{$medium-up} {
        width: 165px;
      }
    }
  }
  .covid-especial-noimg {   
    padding-top: 0;
    position: relative; 
    img {
      position: absolute;
      top: rem-calc(-250);
      width: 80px;
      right: 80px;
      z-index: 30;
      @media #{$medium-up} {
        width: 120px;
        right: 3%;
        top: rem-calc(-210);
      }
      @media #{$large-up} {
      right: 3%;
      top: rem-calc(-210);
      }
      @media #{$xlarge-up} {
        right: 15%;
      }
    }
  }
  .t1-covid {
    position: relative;
    padding-left: 100px;
    padding-right: rem-calc(15);
    text-align: left;
    font-size: 25px;
    @media #{$medium-up} {
        text-align: center;
        font-size: 35px;
      }
    img {
      width: 70px;
      top: -5px;
      left: 0;
      margin-bottom: 0;
      position: absolute;
      @media #{$medium-up} {
        width: 100px;
        top: -30px;
      }
    }
  }
}

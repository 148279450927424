  .main-nav {
    background-color: $primary-color;

    @media #{$medium-up} {
      background-color: $primary-color;
      background-image: linear-gradient(264deg, #008ac0, #00528c);
    }
  }
  .menu-button {
    background-color: $primary-color;
    outline: 0;
    height: rem-calc(70);
    width: rem-calc(70);
    position: absolute;
    right: 0;
    top: 0;
    .hamburger-box{
      position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -13px;
    }
    // .js-menu-open & {
    //   top: rem-calc(60);
    //   @media #{$medium-up} {
    //     top: rem-calc(90);
    //   }
    // }
    @media #{$medium-up} {
      display: none;
    }
  }
  // .menu-wrapper {
  //   background-color: $primary-color;
  //   margin-left: -$mobile-padding;
  //   margin-right: -$mobile-padding;
  //   padding: vr(1.5) $mobile-padding;
  //   margin-top: rem-calc(80);
  //   text-align: center;
  //   @media #{$medium-up} {
  //     margin-top: rem-calc(110);
  //     margin-left: -$column-gutter * 0.5;
  //     margin-right: -$column-gutter * 0.5;
  //   }
  //   @media #{$large-up} {
  //     background-color: transparent;
  //     margin: 0;
  //     padding: 0;
  //   }
  // }
  .site-menu {
    list-style: none;
    text-align: center;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:before,
    &:after {
      content: "";
      height: 1px;
      width: vr(10);
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.5);
      display: block;
      margin: 0 auto;
      @media #{$small-up} {
        display: none;
      }
    }
    &:before {
      margin-bottom: vr(0.75);
    }
    &:after {
      margin-top: vr(0.75);
    }

    margin-bottom: 0;
    padding:vr(1) 0;
    @include bold;
    @include typi("nav");

    @media #{$medium-up} {
      padding: 0;
    }
    li {

      @include single-transition();
      &:hover {
        background-color: $secondary-color;
      }
      @media #{$medium-up} {
        display: inline-block;
        padding: 0;
      }
      &.active {
        background-color: $secondary-color;
      }
    }
    a {
      @include undolink;
      display: block;
      padding: vr(.5);
      color: #fff;
      outline: 0;
      &:focus{
        background-color: $secondary-color;
      }

      @media #{$medium-up} {
        // color: $primary-color;

        padding: vr(1) vr(.5);
        display: block;
        &[aria-haspopup="true"]:hover {
          text-decoration: none;
        }
        &[aria-current="page"] {
          color: #000;
        }
        .sticky-header & {
          padding-top: vr(0.25);
          padding-bottom: vr(0.25);
        }
      }
      @media #{$large-up} {
         padding: vr(1);
      }

      span {
        display: none;
        @media #{$medium-up} {
          display: inline;
        }
      }
    }
    // submenú
    div[aria-label="submenu"] {
      display: none;
    }
  }

  // submenús
  // http://heydonworks.com/practical_aria_examples/#submenus
  @media #{$small-up} {
    [aria-haspopup="true"] {
      position: relative;
    }

    [aria-haspopup="true"] span {
      font-size: 0.75em;
      padding-left: 0.25em;
    }

    div[aria-hidden="true"] {
      display: none;
    }

    [aria-haspopup="true"] + div[aria-hidden="false"] {
      margin-top: 0;
      display: block;
      position: absolute;
      min-width: 200px;
      left: 0;
      width: 100%;
    }

    .site-menu {
      // submenu
      div[aria-label="submenu"] {
        width: 22em;
        text-align: left;
        z-index: 10;
        background-color: $secondary-color;
        position: relative;
        padding: vr(1);
        text-transform: none;
        @include typi("small");

        width: 100%;
        left: 0;
        position: absolute;

        // fletxeta del dropdown
        // .column:before {
        //   @include triangle(6px, $primary-color, bottom);
        //   content: "";
        //   position: absolute;
        //   left: vr(1);
        //   top: -12px;
        //   z-index: 2;
        //   display: block;
        // }

        li {
          margin-bottom: vr(0.5);

          display: block;
          &.last {
            margin-bottom: 0;
          }

          a {
            @include undolink;
            color: #fff;
            padding: 0;
            border-right: 0;
            &:hover{
              text-decoration: none;
              border: none;
              &:after{
                background-color: #fff;
                opacity: .7;
              }
            }

          }
        }
      }
      .destacat-menu{
        h2{
          margin-bottom:.5em;
          font-size: rem-calc(20);
        }
        p{
          color: #fff;
          font-size: rem-calc(14);
          @include regular;
        }
        a{
          padding:0;
          margin:0;
          color:#fff;
          display: block;

          &:hover{
            text-decoration: none;
            img{
              opacity: .9;
            }

          }
          &.btn{
            display: inline-block;
            padding:vr(.35) vr(1);
            margin-bottom: vr(1);
          }
          &.btn-usuaris{
            background-color: #fff;
            color: #00528c;
            svg {
              margin-top: 3px;
              margin-left: rem-calc(15);
            }
          }
          &.btn-covid{
            background-color: #e15c56;
            color: #fff;
            border: 1px solid #e15c56;
            &:hover {
              border: 1px solid #fff;
            }
          }

        }
      }
      .dest-men1, .dest-covid {
        border-bottom: 1px solid #62abc4;
        padding-bottom: 0;
        margin-bottom: rem-calc(15);
      }
      .dest-covid {
        position: relative;
        svg {
          position: absolute;
          top: 0px;
          right: -60px;
          @media #{$medium-up} {
            //top: 45px;
          }
        }
      }
    }
    .sticky-header .site-menu ul li {
      margin-bottom: vr(0.25);
    }
  }



@media #{$small-only} {
  .menu-wrapper {
      //animation: .1s fade-in .5s linear forwards;
      //background: #e1e1e1;
      //border: 5px solid #fff;
      background:$primary-color;
      bottom: 0;
      left: 0;
      opacity: 0;
      overflow: auto;
      margin-top: 70px;
      position: fixed;
      right: 0;
      top: 0;
     // transition: .15s transform cubic-bezier(0.895,.03,.685,.22);
      //transform: translate3d(100%,0,0);
      padding-bottom: 3em;
      min-height: 100vh;
      overflow: hidden;
      visibility: hidden;
        transform: none !important;
      //display: none;

  }
  .js-menu-open .menu-wrapper {
    transform: translate3d(0,0,0);
    transition-timing-function: cubic-bezier(0.165,.84,.44,1);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    position: relative;
     margin-top: 0;
     max-width: 100%;
     display: block;
     //opacity: 1;
     animation: .1s fade-in .5s linear forwards;
     visibility: visible;

  }

  .main-header{
    position: absolute;
    width: 100%;
    z-index: 20;
    background-color: #fff;
    top:0;
    height: 70px;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    max-width: 100%;


  }
  .js-menu-open .main-header{

  }
  .main-content{
    margin-top:70px;
  }
  /*body.js-menu-open{
    overflow: hidden;
  }*/

}
@keyframes fade-in{0%{opacity:0}100%{opacity:1}}


.centre{
  .main-nav{
    background-color: transparent;
    background-image: none;
    .site-menu{
      li{
        &:hover{
            background-color: transparent;
        }

        a{
          background-color: transparent;
          position: relative;
          letter-spacing: 0.75px;
          @media #{$medium-up} {
            color: $secondary-color;
          }
          &:after{
            content:'';
            background-color: $primary-color;
            position: absolute;
            bottom: 0;
            left: 50%;
            height: 3px;
            width:0;
            opacity: 0;
            @include single-transition();
          }
          &:hover{
            text-decoration: none;
            background-color: transparent;
            &:after{
              opacity: 1;
              left:0;
              width:100%;
            }

          }
        }
        &.active{
          background-color: transparent;
          /*a{
            &:after{
              opacity: 1;
              left:0;
              width:100%;
              background-color: $secondary-color;
            }
          }*/
        }
      }
    }
  }

}

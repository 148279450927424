form.basic, form.houdiniForm {
  margin-bottom: vr(1);
  .protecciodades, .checkbox-unit{
    margin-bottom: vr(2);
    label{
      position: relative;
      padding-left: vr(1);

      text-transform: none;
      input{
        position: absolute;
      left: 0;
      top: 2px;
      font-size: 1.5625rem;
      }
    }

  }
  .checkbox-unit{
    margin-bottom: vr(1);
  }
  fieldset {
    border: 0;
    padding: rem-calc(0 30 20);
    margin: 0 0 vr(1.5);
    border: 2px solid $primary-color;
    &:focus {
      outline: none;
    }
  }
  legend {
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    color: $primary-color;
    margin-bottom: vr(1);
    width: 100%;
    text-align: center;
    font-size: rem-calc(18);
    white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    span {
      border-bottom: 1px solid $primary-color;
      padding-bottom: vr(.25);
    }
  }
  label,
  .label,.type-label {
    @include typi('small');
    @include bold;
    letter-spacing: 1.4px;
    display: block;
    text-align: left;
    margin-bottom: vr(.25);
    text-transform: uppercase;
    &.error{
      @include typi('smallest');
      @include regular;
      margin-top: vr(-1.35);
      color: red;
      display: block;
      text-align: left;
      text-transform: none;
      font-weight:normal;
    }
    &.error-avis {
        @include typi('normal');
        margin-top: -15px;
        margin-bottom: 15px;
    }
  }
  .protecciodades{
    label.error{

      margin-top: vr(-.35);

    }
  }
  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="number"],
  select,
  textarea {
    // @include book;
    width: 100%;
    padding: rem-calc(13) vr(.5);
    background-color: #fff;
    border: solid 1px #979797;
    border-radius: 4px;
    margin-bottom: vr(1.5);
    &:focus {
      background-color: darken(#f7f7f7,2%);
    }
    &.error {
      border: 1px solid red;
    }
  }
  input[type="file"] {
    margin: rem-calc(5 0 36);
    max-width: 220px;
    @media #{$medium-up} {
      max-width: 100%;
    }
  }
  .select-wrapper {
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      // @include sprite('fletxa-select');
      top: 9px;
      right: vr(.75);
      font-size: 16px;
      pointer-events: none;
    }
  }
  select {
    background-color: #fff;
    //appearance: none;
    border-radius: 0;
    //padding: rem-calc(14) rem-calc(70) rem-calc(14) vr(.5);
    padding: rem-calc(14) vr(.5);
    position: relative;
    &::-ms-expand {
      display: none;
    }
  }
  .select2-container{
    //padding: rem-calc(14) rem-calc(70) rem-calc(14) vr(.5);
    margin-bottom: vr(1.5);

    &:focus {
      background-color: darken(#f7f7f7,2%);
      outline:0;
    }
    .select2-selection--single{
      padding: rem-calc(14) rem-calc(70) rem-calc(14) 0;
      height: auto;
      &:focus {
        background-color: darken(#f7f7f7,2%);
        outline:0;
      }

      .select2-selection__rendered{
        line-height: 1;
        font-size: rem-calc(16)!important;
      }
    }
  }
  .particular-empresa {
    margin-bottom: rem-calc(20);
  }
  .empresa, .particular {
    display: none;
  }
  .visible {
    display: block;
  }
  .form28-elem344 {
    margin-bottom: rem-calc(20);
  }
}
.js{
  #field_344_other,#field_329_other,.form28-elem346,.form28-elem347,.form28-elem363,.form27-elem362,.form27-elem331,.form27-elem332 {
    display: none;
  }
}
.select2-container--default .select2-results>.select2-results__options {
  max-height: 350px!important;
      padding: 8px 0;

    background-color: #ffffff;
    -webkit-border-radius: 0 0 2px 2px;
    border-radius: 0 0 2px 2px;
    -webkit-box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 100%!important;
}
.select2-results__option{
  padding: vr(.5) vr(1)!important;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #eee!important;
    color: #222!important;
}
.select2-search__field{
  background: url('img/ico-search-bl.svg') vr(.25) 50% no-repeat;
  padding-left: vr(1.4)!important;
  &:focus {
      outline:0;
  }
}
.select2-selection__rendered {
  li {
    &:before {
      content: none;
    }
  }
}

.houdiniForm {
  input{
    outline: none;
  }
  input[type="checkbox"] {
    -webkit-appearance: checkbox;
    appearance: checkbox;
    margin-bottom: 0;
    @media #{$medium-up} {
      margin-bottom: vr(1);
    }
    &:focus{
        box-shadow: none!important;
    }
  }
  input[type="radio"] {
    -webkit-appearance: radio;
    appearance: radio;
    &:focus{
        box-shadow: none!important;
    }
  }
  select {
    -webkit-appearance: menulist;
    appearance: menulist;
    height: rem-calc(54);
    padding: 2rem;
    width: 100%;
    border-radius: 0;
  }
  textarea{
    height: 9rem;
  }
  .button{
    margin: vr(2) 0;
  }
  a{
    text-shadow: none;
    box-shadow: none;
  }
  p.type-label{
    margin-bottom: vr(.25);
  }
  .help-block{
    margin-top: vr(-0.5);
    p{
      @include typi('smallest');
      margin-bottom: vr(1);
      a{
        text-decoration: underline;
      }
    }
  }
  label.error + .help-block{
    margin-top: 0;
  }
  label.error:empty + .help-block{
    margin-top: vr(-1.2);
  }
  .radio-group{
    margin-bottom: vr(1.5);
    label{
      @include regular;
      margin-bottom: vr(.4);
    }
    input[type="text"]{
      margin-bottom: 0!important;
    }
    &.inline{
      label{

        @media #{$medium-up} {
          display: inline-block;
          width: 25%;
          margin: 0;
        }
        &.error{
          @include typi('smallest');
          @include regular;
          margin-top: vr(-.35);
          color: red;
          display: block;
          text-align: left;
          text-transform: none;
          font-weight:normal;
          width: 100%;
        }
      }
    }
  }
  .protecciodades{
    label.error{
      margin-top: vr(-.75);
    }
  }
  .chzn-select + label.error{
    margin-top: vr(-.35);
  }
  .btn{
    @include typi('big');
    margin: vr(.5) 0 0;
     @media #{$medium-up} {
      margin-top: 0;
    }
  }
  .bg & {
    .btn{
      background-color: $primary-color;
      color:#fff;
      border-color:  $primary-color;
      &:hover{
        background-color: #fff;
        color: $primary-color;
        box-shadow: 0 2px 2px rgba($primary-color,.24), 0 0 2px rgba($primary-color,.12);
      }
    }
  }
  .button-form{
    margin-bottom: 2em;
  }
}

/* IE9 hack to hide the arrow */
@media screen and (min-width:0\0) {
  select {
    z-index: 1;
    // padding: .5rem 1.5rem .5rem 1rem;
  }
  .select-wrapper:after {
    z-index: 5;
  }
  .select-wrapper:before {
    position: absolute;
    top: 0;
    right: 4rem;
    bottom: 0;
    z-index: 2;
    content: "";
    display: block;
    width: 1.5rem;
    height: 3.3rem;
    background-color: #fff;
    // border-top: 1px solid $tertiary-color;
    // border-bottom: 1px solid $tertiary-color;
  }
  .select-wrapper select:hover,
  .select-wrapper select:focus,
  .select-wrapper select:active {
    color: #555;
    background-color: #eee;
  }
  select:focus {
    background-color: #fff !important;
  }
}

// estils per input[type="file"]
.js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  display: block;
  cursor: pointer;
  padding: rem-calc(15) vr(.5);
  background-color: #fff;
  // border: 1px solid $tertiary-color;
  margin-bottom: vr(1);
  text-transform: none;
}
.upload-icon {
  fill: $primary-color;
}
.no-js .inputfile + label {
    display: none;
}
.inputfile:focus + label {
  background-color: darken(#f7f7f7,5%);
}
.inputfile:focus + label,
.inputfile.has-focus + label {
    // outline: 1px dotted #000;
    // outline: -webkit-focus-ring-color auto 5px;
    outline: 3px solid $focus;
}
.inputfile + label * {
  pointer-events: none;
}

// validació
label.error {
  @include typi('small');
  margin-top: vr(-.75);
  color: red;
  display: block;
  text-align: left;
  text-transform: none;

}
form.houdiniForm select.prefix {
  border-radius: 3px;
  padding: 0;
  padding-left: 5px;
  height: 50px;
}

.colabora-form {

  .bg-green {
    p, ul {
      text-align: left;
    }
    a {
      @include undolink;
      color: $body-font-color;
      text-decoration: underline;
    }
  }

  .form28-elem344,.form28-elem345,.form27-elem329,.form27-elem330 {
    margin-top: vr(.6);
        text-align: left;
    label {
      font-size: rem-calc(16);
      font-weight: bold;
      @media #{$medium-up} {
        text-align: center;
        display: inline-block;
        margin-right: vr(.5);
        border: solid 1px #778e23;
        border-radius: 4px;
        width: 22%;
        padding: vr(1) 0;
        @include single-transition();
        &.selected{
          background-color: $bg-color;
          border-color: $primary-color;
        }
      }
      @media #{$large-up} {
        margin-right: vr(1);
        font-size: rem-calc(18);
      }
      &.error{
        border: none;
        padding: 0;
        width: auto;
        margin: vr(.25);
      }
      input {
        margin-bottom: rem-calc(5);
      }
      span {
        display: block;
      }
    }
  }
  #field_344_other {
    margin-top: rem-calc(15);
  }
}
.select2-container{
  min-width: 100%;
}
form.newsletter{
  input{
    color: $body-font-color;
  }
}
div.gracies, div.error{
  padding: vr(1) vr(2) vr(4);
  h2{
    border: none;
    font-size:rem-calc(30);
  }
  h3{
    font-size: rem-calc(30);
  }
  p{
    text-align: center!important;
  }
}
div.gracies{
  p{
    @include typi('big');
  }
}

/* formulari envia el teu curriculum*/

#form26,form.basic, form.houdiniForm {
  counter-reset: my-sec-counter;
  fieldset {
    background: #f7f7f7;
    border:none;

      position: relative;
      margin-top: 4em;
      padding-top: 4.5em;

    &::before {
        /* Increment "my-sec-counter" by 1 */
        counter-increment: my-sec-counter;
        content: counter(my-sec-counter);
        position: absolute;
        top: -.95em;
        z-index: 2;
        left:0;
        background-color: $primary-color;
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        color:#fff;

    }
    legend{
      text-transform: none;
      text-align: left;
      background-color: #fff;
      color: $body-font-color;
      position: absolute;
      margin-left: 0;
      padding:.35em 1em .35em 2.6em;
      top: -.95em;
      left: 0;
      &.opcional{
        &:before{
           content: '*';
            position: absolute;
            top: 3px;
            z-index: 3;
            left:0;
            color: $primary-color;
            background-color: #f7f7f7;
            width: 35px;
            height: 36px;
            line-height: 45px;
            text-align: center;
        }
      }
    }
    label{
      @include regular;
      text-transform: none;
    }
    .checkbox-group{
      margin-bottom: 1.5rem;
      input{
            margin-bottom: .375rem;
      }
    }
  }
}
.bg{
  #form26,form.basic, form.houdiniForm {
    fieldset {
      background: #fff;
      background-color: rgba(#fff,.8);

      legend{
        background: $bg-color;

      }
    }
  }
}
.info-message{
  max-width: 600px;

  text-align: center;
  padding: 2em;
  margin: vr(1) auto;

  svg{
    width:100px;
    height: 100px;
    margin-bottom: 1em;
  }

  &.info-message-ok{
    border:solid 2px #198838;
    border-radius: 6px;
    svg{
      fill:#198838;
    }
  }
  &.info-message-error{

    border:solid 2px #ee0000;
    border-radius: 6px;
    svg{
      fill:#ee0000;
    }
    h2{
      color: #ee0000;
    }
  }
  ul{
    @include undolist;
  }
}
.info-message-error{

}

.app {
    /**
     * GLOBALS
     */
    a,
    button {
        cursor: pointer;
    }
    /**
     * PADDINGS & MARGINS
     */
    .pd {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .pd_w {
        padding-left: 10px;
        padding-right: 30px;
    }
    .pd_t {
        padding-bottom: 20px;
    }
    .pd_b {
        padding-bottom: 20px;
    }
    .pd_t_xxl {
        padding-top: 40px;
    }
    .pd_b_xxl {
        padding-bottom: 40px;
    }
    .pd_xxl {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    /**
     * ALIGNS
     */
    .txt_left,
    .txt_left h1 {
        text-align: left;
    }
    .txt_right {
        text-align: right;
    }
    .txt_center {
        text-align: center;
    }
    /**
     * COLORS
     */
    .txt-w {
        color: white!important;
    }
    /**
     * BUTTONS
     */
    .cta-btn {
        border-radius: 8px;
        background: #157cbd;
        color: white;
        padding: 18px 20px;
        font-family: sisco_bookregular,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
        max-width: 300px;
        width: 100%;
        border-bottom: none!important;
        display: inline-block;
        text-shadow: none!important;
        text-align: center;
        &.negatiu{
            background: #FFF;
            border: 1px solid #157cbd;
            color: #157cbd;
            margin-top: 1em;
            &:hover{
                color: #FFF;
            }
        }
    }
    .cta-btn:hover {
        background: #00528c;
    }
    /**
     * NEW BLOCKS
     */
    .v-wrap {
        height: 100%;
        white-space: nowrap;
        position: relative;
        //text-align: center;
        .v-box {
            //display: inline-block;
            vertical-align: middle;
            white-space: normal;
        }
        svg {
            position: absolute;
            display: block;
            width: 100px;
            top: 0px;
            right: 0px;
            @media #{$medium-up} {
                top: 20px;
                right: 20px;
                width: 150px;
            }
            @media #{$large-up} {
                width: 250px;
            }
        }
    }
    .v-wrap:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 100%;
    }
    .triangle-up-right {
        width: 100%;
        height: 0;
        padding-left: 100%;
        padding-top: 8%;
        overflow: hidden;
    }
    .triangle-up-right:after {
        content: "";
        display: block;
        width: 100%;
        height: 0;
        margin-left: -1280px;
        margin-top: -102px;
        border-left: 1280px solid transparent;
        border-top: 100px solid #00528c;
    }
    .triangle-down-left {
        width: 100%;
        height: 0;
        padding-bottom: 10%;
        overflow: hidden;
    }
    .triangle-down-left:after {
        content: "";
        display: block;
        width: 100%;
        height: 0;
        border-right: 1200px solid transparent;
        border-bottom: 120px solid #00528c;
    }
    .blue-block {
        background-color: #00528c;
        position: relative;
        min-height: 230px;
        width: 100%;
        color: #FFF!important;
        font-family: sisco_bookregular,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
        h1 {
            font-family: siscobold, sans-serif;
            font-size: 3rem;
            line-height: 2.6rem;
            margin-bottom: 1rem;
        }
        h3 {
            font-size: 1.4rem;
            line-height: 1.4rem;
            margin-bottom: 1.4rem;
        }
        p {
            //width: 80%;
            //margin: 0 auto;
        }
    }
    .featured-ico {
        object-fit: contain;
        object-position: 0;
        width: 100px;
        height: 64px;
        display: block;
        clear: both;
        margin-bottom: 20px;
    }
    .video-responsive {
        position: relative;
        padding-bottom: 56.25%;
        /* 16/9 ratio */
        padding-top: 30px;
        /* IE6 workaround*/
        height: 0;
        overflow: hidden;
        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    ul.featured {
        padding: 0;
        display: block;
        clear: both;
        width: 90%;
        margin: 0 auto;
        li {
            font-family: sisco_bookregular,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
            img {
                display: inline-block;
                max-height: 120px;
                margin-bottom: 0;
            }
            strong {}
            .medium-3 {
                text-align: right;
            }
            .medium-9 {
                font-size: 22px;
                font-weight: 400;
                font-family: sisco_bookregular,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
                vertical-align: middle;
                text-align: left;
                padding-top: 40px;
            }
        }
    }
    ul.remember {
        display: flex;
        align-items: stretch;
        padding: 20px 20px;
        margin: 30px 40px;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        li {
            display: block;
            width: 25%;
            text-align: center;
            font-size: 14px;
            line-height: 1.4rem;
            font-weight: 400;
            font-family: sisco_bookregular,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
            padding: 0 10px;
            img {
                display: inline-block;
                max-height: 74px;
                margin-bottom: 0;
            }
            span {
                display: block;
                margin: 0;
            }
            strong {
                display: block;
                font-size: 18px;
                margin-bottom: 0;
                font-weight: 400;
                font-family: sisco_bookregular,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
            }
            &:last-child{
                img{
                     margin-top:22px !important;
                     margin-bottom: 20px !important;
                }
             }
        }

    }
    @media only screen and (max-width: 600px) {
        .blue-block {
            .ico-img {
                height: 170px;
            }
            h1 {
                margin-top: 1.6rem!important;
            }
            p {
                clear: both;
            }
            .pd_t_xxl {
                padding-top: 20px;
            }
        }
        ul.featured {
            li {
                .medium-3 {
                    text-align: center!important;
                }
                .medium-9 {
                    padding-top: 10px;
                    text-align: center!important;
                }
            }
        }
        ul.remember {
            margin: 30px 0;
            display: block;
            width: 100%;
            li {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
    form.houdiniForm {
        fieldset {
            background-color: transparent;
            padding: 0;
            &:before {
                content: none;
            }
            legend {
                background-color: transparent;
                color: #fff;
                padding: 0;
                position: relative;
            }

        }
        .text-legal {
                a {
                    color: #fff!important;
                    text-decoration: underline!important;
                }
            }
        .btn {
            color: #fff;
            border: 1px solid #fff;
        }
        select.prefix {
            color: #000;
        }
    }
    p.boto a {
        color: #fff;
        border: 1px solid #fff;
    }
    .message {
        background-color: transparent;
        color: #000;
    }
    .btn-form {
        li {
            a {
                color: #fff;
                background-color: transparent;
                border: 1px solid #fff;
                min-height: rem-calc(150);
                white-space: normal;
                font-size: rem-calc(20);
                position: relative;
                &:after {
                    content: '';
                    background-image: url('img/arrow.svg');
                    background-repeat: no-repeat;
                    width: 25px;
                    height: 21px;
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                }
                &:hover {
                    background-color: #157cbd;
                }
            }
        }
    }
    .img-svg {
        width: 60px;
        margin: 15px;
    }
    .img-svg2 {
        width: 35px;
        margin: 15px !important;
    }
    .big {
        margin-bottom: 5px;
    }
    .menu-icos {
        li {
            display: inline-block;
            font-size: rem-calc(25);
            position: relative;
            padding-left: 60px;
            padding-top: 15px;
            padding-right: 60px;
            a {
                @include undolink;
                color: #fff;
                font-weight: bold;
            }
            &.first {
                &:before {
                    content: '';
                    background-image: url('img/ico-phone-app.svg');
                    background-repeat: no-repeat;
                    width: 40px;
                    height: 42px;
                    position: absolute;
                }
            }
            &.last {
                &:before {
                    content: '';
                    background-image: url('img/ico-ar-app.svg');
                    background-repeat: no-repeat;
                    width: 40px;
                    height: 40px;
                    position: absolute;
                }
            }
        }
    }
}
.list-phone {
    margin-top: rem-calc(70);
    @media #{$medium-up} {
        margin-top: 0;
        column-count: 2;
    }
    li {
        position: relative;
        &:before {
            content: '';
            background-image: url('img/ico-phone-white.svg');
            background-repeat: no-repeat;
            width: 11px;
            height: 11px;
            position: absolute;
            top: 5px;

        }
        a {
            display: block;
            @include undolink;
            color: #fff;
        }
    }
}

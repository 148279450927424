.donatius form.houdiniForm .radio-group label {
      font-size: rem-calc(16);
      font-weight: bold;
      @media #{$medium-up} {
        text-align: center;
        display: inline-block;
        margin-right: vr(.5);
        border: solid 1px #778e23;
        border-radius: 4px;
        width: 40%;
        padding: vr(1) 0;
        @include single-transition();
        &.selected{
          background-color: $bg-color;
          border-color: $primary-color;
        }
      }
      @media #{$large-up} {
        margin-right: vr(1);
        font-size: rem-calc(18);
      }
      &.error{
        border: none;
        padding: 0;
        width: auto;
        margin: vr(.25);
      }
      input {
        margin-bottom: rem-calc(5);
      }
      span {
        display: block;
      }
    }
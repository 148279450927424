// Search mobile
.s-mobile {
  max-width: vr(10);
  margin: 0 auto vr(1.5);
  position: relative;
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }
  label,
  legend {
    @include visually-hidden;
  }
  input {
    padding: vr(.5) vr(1.3) vr(.5) vr(.5);
    width: 100%;
    background: #F4F4F4;
    border-radius: 6px;
    box-sizing: border-box;
    outline: 0;
    border:solid 1px transparent;
    &:focus{
      background: #fff;
      border-color:#ffbf47;
    }
    &[type="search"] {
      appearance: none;
    }
  }
  button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
    img {
      margin: 0;
      width: rem-calc(22);
      height: rem-calc(22);
    }
  }
}

// Search desktop
.s-desktop {
  //display: none;
  margin-bottom: 0;
  @media #{$medium-up} {
    display: inline-block;
    vertical-align: middle;
    float: right;
    margin-left: vr(.5);
  }
  .sticky-header & {
    display: none;
  }
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    //background-color: $primary-color;
  }
  legend,
  label {
    @include visually-hidden;
  }
  input[type="search"] {
    appearance: none;
  }
}

[role="search"] { // http://adrianroselli.com/2015/12/responsive-progressive-accessible-vanilla-search.html
  // box-sizing: border-box;
  // border-radius: 4px;
  // border: 1px solid transparent;
  padding: 0;
  margin: 0;
  /*max-width: 36em;*/
  // line-height: 1.6;
  display: inline-block;
  // line-height: 0;
  // transition: all .2s ease-in;
  background-color: #fff;
  // border: 1px solid rgba(0, 0, 0, .5);
  width: 100%;
  // height: rem-calc(30);
  // @media #{$large-up} {
  //   height: rem-calc(39);
  // }
}

[role="search"].notempty {
  background-color: #fff;
  // border: 1px solid rgba(0, 0, 0, .5);
}

[role="search"]::after {
  content: "";
  display: block;
  clear: both;
}

[role="search"] label {
  display: inline-block;
  overflow: hidden;
  margin: 0;
  float: right;
  cursor: pointer;
  // transition: all .2s ease-in;
  // height: 1.5em; /* for IE */
  text-indent: -1000px;
  width: 0;
  height: 27px;
}

[role="search"] label img {
  margin: 5px;
  vertical-align: baseline;
  // fill: #fff;
  // width: 1.5em;
  // margin-top: .5em; /* Must go away for IE. Ugh. */
  // transition: all .2s ease-in;
  // margin: 4px 5px 0 0;
  // height: 20px;
  // @media #{$large-up} {
  //   height: auto;
  // }
}

// [role="search"] input[type="search"]:focus ~ label svg,
// [role="search"].notempty label svg {
//   fill: transparent;
// }

[role="search"] input[type="search"] {
  border: none;
  border-radius: 6px;
  background-color: #E3E3E3;
  margin: 0;
  padding: 0;
  width: 14em;
  height: 37px;
  transition: all .3s ease-in;
  box-sizing: border-box;
  position: absolute;
  top: -1px;
  right: 36px;
  max-width: 0;
  z-index: 2;
}

[role="search"] input[type="search"]:focus {
  outline: none;
  //background-color: #fff; /* Needed for non-JS */
}

input::-webkit-input-placeholder {
  color: #757575; /* a11y FTW! */
}

input:-ms-input-placeholder {
  color: #757575; /* a11y FTW! */
}

input::-moz-placeholder {
  color: #757575; /* a11y FTW! */
  opacity: 1; /* https://www.quirksmode.org/blog/archives/2015/12/styling_placeho.html */
}

[role="search"] button {
  // font-size: 90%; /* This allows the width in ems to scale  */
  // width: 2em;
  // height: auto;
  margin: 0;
  border: none;
  padding: 5px;
  background: transparent;
    box-sizing: content-box;
  cursor: pointer;
  // transition: all .2s ease-in;
  float: right;
}

[role="search"] button:hover,
[role="search"] button:focus {
  outline: none;
}

/*[role="search"] input[type="search"]:focus ~ button,
[role="search"].notempty button {
  float: right;
  text-indent: 0;
  width: 17px;
  display: block;
    // margin: 3px 5px 0 0;
}*/

[role="search"] button img {
  display: block;
  margin-bottom: 0;
  // margin: 0 auto;
  // padding-right: .2em;
  // fill: #757575;
  // width: 100%;
  // transition: all .2s ease-in;
    // margin: 2px;
  // height: 20px;
  @media #{$large-up} {
    // height: auto;
  }
}

// [role="search"] button:hover svg,
// [role="search"] button:focus svg {
//   fill: #00f;
// }

// [role="search"] input[type="search"]:focus ~ button svg,
// [role="search"].notempty button svg {
//   /*height: 100%;*/
//   /*width: 4em;*/
// }

// @media all and (min-width: 24em) {

  [role="search"] {
    background-color: transparent;
    // border: 1px solid rgba(0, 0, 0, 0);
    width: auto;
  }

  [role="search"] label {
    text-indent: 0;
    width: auto;
  }

  [role="search"] input[type="search"]:focus ~ label,
  [role="search"].notempty label {
    text-indent: -1000px;
    width: 0;
  }

  [role="search"] input[type="search"] {
    width: .01em; /* Must have min width or Safari won't work*/
    padding: 0;
    top: 6px;
    // float: left;
  }
  [role="search"].open input[type="search"] {
    width: 14em;
    padding: .5em;
    max-width: 24em;
    top: 6px;
  }

  /*[role="search"] label:matches(:hover, :focus) /for/ input, CSS Selectors 4, 13.5. Reference combinators http://www.w3.org/TR/selectors4/#idref-combinators, no support */
  /*[role="search"] input[type="search"]:focus,
  [role="search"].notempty input[type="search"] {
    padding: vr(.25) .75em;
    margin-top: -5px;
    width: 10em;
      border: 1px solid $light;
      @include typi('smaller');
    // margin-top: 3px;
    // @media #{$large-up} {
    //   margin-top: 6px;
    // }
  }*/

  [role="search"] button {
    //display: none;
    width: .01em;
    //text-indent: -1000px;
    transition: opacity .3s ease-in;
    position: absolute;
    top: 2px;
    opacity: 0;

    height: 20px;
    right: 36px;
    top: 11px;
  }
  [role="search"].open button {
    opacity: 1;
    width: 20px;
    top: 11px;

  }

  // [role="search"].notempty button:hover svg,
  // [role="search"].notempty button:focus svg {
  //   fill: #00f; Color for when JS fires
  // }

  // [role="search"] button:hover svg,
  // [role="search"] button:focus svg {
  //   fill: #fff; /* Color for non-JS */
  // }

// }

/* Llista resultats */
.list.cercador{
  article{
    border-bottom: 1px solid #ddd;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    ol {
      margin-left: 0;
      margin: 0 0 vr(.25);
      li{
        margin-left: 0;
        font-size: rem-calc(13);
        a{
          color: $body-font-color;
          @include undolink;

        }
        &:after {
          content: '>';
          padding-left: 3px;
        }
      }
    }
    h2{
      @include light;
      margin-bottom: vr(.35);
      a{
        text-decoration: none;
        border: none;
        @include undolink;
      }
    }
  }
}
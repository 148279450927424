.block-list {
  @include block-grid(1);
  // margin-bottom: vr(1.5) !important;
  //margin-left: -$column-gutter/2 !important;
  //margin-right: -$column-gutter/2 !important;

  li {
    margin-bottom: 0;
    padding-bottom: 0;
    &:before {
      display: none;
    }
  }
    .icona {
        min-height: 100px;
    }
  &--2 {
    @media #{$medium-up} {
      @include block-grid(2);
    }
  }

  &--3 {
    @media #{$medium-up} {
      @include block-grid(3);
    }
  }
  &--4 {
    @include block-grid(1);
    @media only screen and (min-width: 35em) {
      @include block-grid(2);
    }

    @media #{$medium-up} {
      @include block-grid(3);
    }
    @media #{$large-up} {
      @include block-grid(4);
    }
  }
  &.serveis{
    a{
      //color: $body-font-color;
      @include undolink;
      position: relative;
      display: block;
      padding: vr(.75) vr(1.75) vr(.75) vr(.25);
      border-bottom: solid 1px #979797;
      &:after{
        content: '';
        width: 0;
        height: 3px;
        background-color: transparent;
        position: absolute;
        bottom: -2px;
        left: 50%;
        @include single-transition();
      }

      &:before {
          position: absolute;
          top: 50%;
          margin-top: -14px;
          right: 10px;
          float: right;
          content: "\203A";
          color: $primary-color;
          opacity: 0;
          @include single-transition();
          font-size: rem-calc(24);
      }
      &:hover{
        text-decoration: none;
        border-color: $primary-color;
        //background-color: #f7f7f7;
        &:before {
            opacity: 1;
        }
        &:after{
            width: 100%;
            left: 0;
            background-color: $primary-color;
          }
      }
      &:focus{
        text-decoration: none;
        border-color: transparent;
        background-color: #f7f7f7;
        outline: 3px solid #f7f7f7;
        &:after {
            opacity: 1;
        }

      }
    }
    h2{
      @include typi('normal');
      margin-bottom: vr(.25);
    }
    p{
      @include typi('small');
      margin-bottom: vr(.25);
      position: relative;
      color: $body-font-color;
      padding-left: vr(.6);
    }
    p.ubicacio-servei{
      &:before{
        content: '';
        display: block;
        @include sprite('ico-localitzacio-b');
        position: absolute;
        top: 2px;
        left: 0;
      }
    }
  }
  &.abs{
    h2{
      margin-bottom: vr(.75);
    }
    img{
      margin-bottom: vr(.25);
    }
    ul{
      margin-bottom: vr(2);
      li{
        @include regular;
        margin-bottom: vr(.35);
        &:first-child{
          @include bold;
        }
      }
    }
    a{
      @include undolink;
      @include typi(normal);
      &:visited{
        color: $secondary-color;
      }
    }
  }
}

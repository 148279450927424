.cap-consultori {
	.main-content{

			.dades-contacte{
				margin: vr(2) 0;
				text-align: center;
				p{
					margin-bottom: vr(.15);
				}
				.telefon{
					@include bold;
					font-size: rem-calc(28);
				}
			}

		.header{
			text-align: center;
			margin-top: vr(1);
			@media #{$medium-up} {
				margin-top: 0;
			}
			img{
				min-width:150px;
			}
			h1{
				margin-top: 0;
				text-transform: none;
			}
		}
		.aside{
			margin-top:0;
		}
	}
	.imatge{
		max-height: 350px;
		overflow: hidden;
		@media #{$large-up} {
			img{
				margin-top: -5%;
			}
		}
		@media only screen and (min-width: 1200px){
			img{
				margin-top: -10%;
			}
		}
	}

}
.centre {
	.main-content{
		.dades-contacte{
			background-color:#f7f7f7;
			margin:0 0 vr(2);
			padding: vr(1.5);
			text-align: center;
			svg{
				width: 64px;
				height: 64px;
			}
			p{
				margin-bottom: vr(.15);
				font-size: rem-calc(20);
				&:last-child{
					margin-bottom: 0;
				}
			}
			.telefon{
				@include bold;
				font-size: rem-calc(38);
			}
		}
	}
	.map{
		margin:0 0 vr(2);
	}
	&.actualitat{
		.section{
			background-color: #f9f9f9;
			padding-top:vr(2);
			&.section--acagenda{
				background-color: #fff;
				padding-bottom: 3rem!important;
				.events-item{
					min-height: 100px;
					margin-bottom: vr(1);
				}
			}
		}
		.llista_cards li.block{
			background-color: #fff;
			h2{
				text-transform: none;
			}
		}
	}
	.location{
		    /*padding: vr(1) vr(2);
		    background: rgba(0,0,0,.2);
		    margin: 0 -3rem 1rem;*/
		    background: rgba(0,0,0,.2);
		    padding: vr(1);
		    margin: 0 -1.5rem;
		p{
			margin-bottom: vr(.5);
			@include regular;
			line-height: 1.3;
		}
	}
	.section{
		&.quadre-medic{
			background-color: #f9f9f9;
			padding-top:3rem;
			.servei{
				background: #fff;
				padding:2em;
				margin-bottom:2em;
				h2{
					text-transform: none;
				}
				.list-block-3 li.block{
					background: #fff;
				}
			}
		}
	}

}
.sabiesque{

	@media #{$medium-up} {
		margin: vr(2) 0;
		.columns{
			padding:1em 0 1em 135px;
			svg{
				position: absolute;
				width: 100px;
				height: 100px;
				left:0;
				top:1em;
			}
		}
	}
	@media #{$large-up} {
		.columns{
			padding:1em 135px;
		}
	}
}
.horari-consultori{
	background-color:$bg-color;
	padding: vr(1.5) vr(1);
	margin-bottom: vr(2);
	@media #{$medium-up} {
		padding: vr(1.5);
	}
	svg{
		width:32px;
		height: 32px;
	}
	.horaris{
		ul{
			@include undolist;
			li{
				margin-bottom: vr(1);
				@media #{$large-up} {
					margin: 0;
					padding: vr(.75) vr(1);
					border-right: solid 1px #fff;
					text-align: left;
					display: inline-block;
					&:last-child{
						border:none;
					}
				}
			}
		}
	}
	.mesinfo{
		text-align: left;
		margin-top: vr(2);
		p{
			margin-bottom: vr(.15);
			@include typi('small');
		}
		li{
			@include typi('small');
		}

	}
}

.map-centres{
	height: 350px;
	margin-bottom: 3em;
	@media #{$medium-up} {
		height: 550px;
		.centre & {
			height: 425px;
		}
	}
}
.wrapper-map{
	position: relative;
	#com-arribar{
		position: absolute;
		top:8px;
		left:10px;

		width: 150px;
		background-color: #fff;
		text-align: center;
		border-bottom-left-radius: 2px;
    	border-top-left-radius: 2px;
		box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
		a{
			text-decoration: none;
			@include undolink;
			@include single-transition();
			@include regular;
			display: block;
			padding: .5em 1em .5em 2.1em;
			position: relative;
			svg{
				color: $primary-color;
				fill: $primary-color;
				position: absolute;
				left:12px;
			}
			&:hover{
				background-color: #000;
				color: #fff;
				text-decoration: none;
				transform: scale(1.1);
				svg{
					color: #fff;
					fill: #fff;
				}
			}
		}

	}
}
.terapies-grupals {
  h1 {
    text-transform: none;
    text-align: left;
    margin-top: 0;
  }
  ul {
    &.caixes-bg {
      display: flex;
      flex-wrap: wrap;
      margin-top: rem-calc(40);
      li {
        .item {
          height: 100%;
        }
        .bg {
          height: 100%;
          background-color: $primary-color;
          display: flex;
          flex-direction: column;
          .boto {
            text-align: center;
            margin-top: 30%;
            a {
              border-color: white;
              color: white;
            }
          }
        }
        &:nth-child(3n+1) .bg {
          background-color: $primary-color;
        }
        &:nth-child(3n+2) .bg {
          background-color: $secondary-color;
        }
        &:nth-child(3n+3) .bg {
          background-color: $tertiary-color;
        }
        h3 {
          text-transform: none;
          font-family: $body-font-family;
          padding-top: 20px;
          a {
            text-shadow: none;
            box-shadow: none;
            color: white;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        figure {
          margin-bottom: 0;
        }
        .btn-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 20px 20px 0 20px;
          height: 100%;
          .btn {
            margin: 0;
          }
        }
        .info {
          padding: 0 20px 20px 20px;
          margin-top: auto;
          p {
            color: white;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.mb {
  margin-bottom: 2em;
}
.mtb {
  margin-bottom: 2em;
  margin-top: 2em;
}

/*$primary-color: #007fb1;
$secondary-color: #00528c;
$tertiary-color: #62ABC4;*/
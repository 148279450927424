.bg--estat {
    background-color: $primary-color;
    text-align: center;
    color: #fff;
    padding: 50px 0;
}
.avisa {
    .estat {
        font-size: 40px;
        font-weight: bold;
    }
    .img-top {
        margin-bottom: 0;
    }
}
.estat-avis {
    //border-bottom: 1px solid #fff;
    margin-bottom: 20px;
    //padding-bottom: 20px;
    opacity: .3;
    &:hover {
        opacity: 1;
    }
    img {
        margin-bottom: 0;
    }
    h4, h3, h2 {
        margin-top: 15px;
    }
    h3 {
        margin-bottom: 5px;
    }

}
.destacat-estat {
    border-radius: 8px;
    border: 1px solid #fff;
    //padding: 10px;
    background-color: #fff;
    //color: #000;
    opacity: 1;
    img {
        filter: invert(100%);
    }
}
.estat-box {
    list-style: none;
    position: relative;
    overflow: visible;
    &:before {
            content: "";
            display: block;
            width: 1px;
            //background-image: url("data:image/svg+xml,%3Csvg width='2' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23FFF' fill-opacity='0.9' d='M0 0v20'/%3E%3C/svg%3E");
            background-repeat: repeat-y;
            background-color: rgba(255, 255, 255, .4);
            position: absolute;
            top: 25px;
            left: -25px;
            bottom: 25px;
    }
    li {
            position: relative;
            &:before {
                content: "";
                width: 15px;
                height: 15px;
                position: absolute;
                top: 50%;
                left: -32px;
                border-radius: 50%;
                transform: translateY(-50%);
                background-color: rgba(255, 255, 255, .6);
            }
    }
    li.dest-estat {
        position: relative;
        background-color: #fff;
        border-radius: 8px;
        color: #000;
        svg {
            filter: invert(100%);
        }
        &:before {
            content: "";
            width: 25px;
            height: 25px;
            position: absolute;
            top: 50%;
            left: -37px;
            border-radius: 50%;
            transform: translateY(-50%);
            background-color: rgba(255, 255, 255, 1);
        }
}
}

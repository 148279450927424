.collapsible{
  overflow: hidden;
  position: static;
  background: #fff;
  z-index: 2;
  .estat-box & {
    background: transparent;
    border: 1px solid #fff;
    border-radius: 8px;
    }
    .text-collapsible {
        text-align: left;
        padding: 20px;
        p {
            margin-bottom: 0;
        }
    }
  .titleac{
    background-color: #EDEDED;
    padding: vr(.5);
    margin-top: 0;
    text-align: left;
    margin-bottom: vr(.25);
    font-size: rem-calc(18);
    text-transform: none;
    overflow: hidden;
    z-index: 2;
    position: relative;
    .estat-box & {
        background-color: transparent;
        margin-bottom: 0;
        padding: 15px;
        text-transform: uppercase;
        font-size: rem-calc(16);
    }
    @media #{$medium-up} {
      padding: vr(1);
      text-align: center;
      .estat-box & {
        text-align: left;
        font-size: rem-calc(20);
        }
    }

    button{
      background-color: #EDEDED;
      outline: 0;
      position: relative;
      width: 100%;
      max-width: 600px;
      z-index: 2;
      text-align: left;
      .estat-box & {
        background-color: transparent;
        text-transform: uppercase;
        position: relative;
        margin-left: 35px;
        @media #{$medium-up} {
            margin-left: 50px;
        }
        .ico-estat {
            left: -35px;
            position: absolute;
            top: -4px;
            @media #{$medium-up} {
                left: -40px;
            }
        }
        .info {
            right: 30px;
            position: absolute;
            top: 4px;
            @media #{$medium-up} {
                right: 50px;
            }
            @media #{$large-up} {
                right: 20px;
            }
        }
      }
      @media #{$medium-up} {
        text-align: center;
        .estat-box & {
            text-align: left;
        }
      }
      &:after{
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        top: 50%;
        right: 12px;
        margin-top: -7px;
        border-width: 12px 12px 0 12px;
        border-color: #BDBDBD transparent transparent transparent;

        transition: transform .75s cubic-bezier(0.86, 0, 0.07, 1),transform-origin .75s cubic-bezier(0.86, 0, 0.07, 1),-webkit-transform .75s cubic-bezier(0.86, 0, 0.07, 1),-webkit-transform-origin .5s cubic-bezier(0.86, 0, 0.07, 1),-ms-transform-origin .75s cubic-bezier(0.86, 0, 0.07, 1);
        .estat-box & {
            content: none;
          }
      }
      &:focus{
        color:$primary-color;
        .estat-box & {
            color:#fff;
          }
          &:after{
            border-color: $primary-color transparent transparent transparent;
          }
      }


    }
    button[aria-expanded="true"]:after{
      transform: rotate(-180deg);
    }
  }
  h3{
    font-size: rem-calc(16);
    border-bottom: solid 1px #979797;
    padding: vr(.5) 0 vr(.5) vr(.5);
    margin-bottom: vr(.5);
  }
  .row{
    padding: vr(1) 0;
  }
  div[aria-hidden=true] {
      display: block!important;
      visibility: hidden;
      transform: translateY(-100px);
      overflow: hidden;
      top: 100%;
      left: 0;
      position: absolute;
      height: 0;
  }
  div[aria-hidden=false] {
      position: static;
      visibility: visible;
      z-index: 1;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      -webkit-transition: -webkit-transform .3s ease;
      transition: -webkit-transform .3s ease;
      transition: transform .3s ease;
      transition: transform .3s ease,-webkit-transform .3s ease;
      opacity: 1;
      //max-height: 1600px;
  }
}
.js .collapsible {
  div.row{
    display: none;
  }
  &.init{
    div.row{
      display: block;
    }
  }
}

.fets-xifres{
	.section{
		padding: vr(3) 0 vr(2);
		&.principal{
			background-size:cover;
		}
		svg{
			width:64px;
			height: 64px;
			display: block;
			margin-bottom: vr(1);
			opacity: .7;
			fill:$primary-color;
			path{
				fill:$primary-color;
			}
		}
		&.bg-section{
			background-color: $primary-color;
			color: #fff;
			svg{
				fill:#fff;
				path{
					fill:#fff;
				}
			}
		}
		.bg{
			background-color: $primary-color;
			background-color: rgba($secondary-color,.78);
			padding: 2em;
			width: 100%;
			max-width: 360px;
			color:#fff;
			margin-bottom: vr(1)!important;
			min-height: 500px;
			p{
				margin-bottom: vr(.5);
				strong{
					font-size: rem-calc(28);
				}
			}
			&.equip-ssibe{
				p{
					margin-bottom: vr(1);
					strong{
						display: block;
					}
				}
			}
			svg{
				fill:#fff;
				path{
					fill:#fff;
				}
			}
		}
		p.big{
			font-size: rem-calc(18);
			line-height: 1.3;
			strong{
				@include typi('biggest');
				line-height: 1;
				display: block;
			}
		}
		p,li{
			line-height: 1.3;
			strong{
				font-size: rem-calc(22);
			}
		}
		ul{
			@include undolist;
			@include block-grid(1);

		}
		@media #{$medium-up} {
			.activitats{
				ul{
					@include block-grid(2);
				}
			}
			.equip-ssibe{
				ul{
					li{
						display: inline;
					    float: left;
					    clear: none;
					    width: auto;
					    strong{
					    	display: block;
					    }
					}
				}
			}
		}
	}
}
.urgencies{
    h2{
        padding-top: vr(1);
    }
    .fons{
        background-color: #00528c;
        color: #fff;
    }

    img{
        padding-bottom: vr(1);
    }

    .tab-interface {
        .bg{
            padding-bottom: vr(2);
            @media #{$medium-up} {
                padding-bottom: vr(3);
            }

        }
        ul.seccions{
            li{
                width: 100%;
                @media #{$medium-up} {
                    width: 35%;
                }

            }
        }
    }


    .btn{
        border: 1px solid #00528c;
        margin-right: vr(1);
        line-height: normal;
        font-size: 1rem;
        text-transform: none;
        padding: .75rem 1.5rem 1rem;
        &.arribar{
            svg{
                position: relative;
                top: 5px;
                left: -5px;
            }
        }
        &.area{
            svg{
                position: relative;
                top: 6px;
                left: -5px;
                }
        }
    }

   .medium-5 {
    > .caixa_blava{
        margin-top: vr(1);
        @media #{$medium-up} {
            margin-top: 0;
        }

    }

   }
}

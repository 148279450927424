.video-link {
    display: inline-block;
    margin: 0 20px;
    text-align: center;
    position: relative;
}
.video-link img {
    max-width: 100%;
    height: auto;
    display: block;
}
.video-link .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
}
.mfp-iframe-holder .mfp-content {
    max-width: 900px;
    height: 90%;
}
.mfp-iframe-holder.short .mfp-content {
    max-width: 360px; /* Adjust width for Shorts */
}

.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; // Relació d'aspecte 16:9
    height: 0;
    overflow: hidden;
    margin-bottom: 2rem;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
